@import '../../../styles/functions';
@import '../../../styles/mixins';

.data-settings-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: color(black);
  z-index: 10000;

  @include dataSettingsHeaderStyles();

  &__help-button {
    display: flex;
    justify-content: flex-end;
  }

  &__data-select {
    min-height: 100vh;
    background-color: color(white);
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding: 3.2rem 2rem;
    margin-bottom: 100px;
    flex: 1;
  }

  &__selection-side-bar {
    min-width: 300px;
  }
}
