@import '../../../styles/functions';

.hokej-player-management-rolldown {
  width: 100%;
  vertical-align: middle;
  background-color: color(cool-gray, 10);

  td {
    padding: 0.2rem 0.8rem;
  }

  &--first {
    td {
      padding-top: 0.8rem;
    }
  }

  &--last {
    td {
      padding-bottom: 0.8rem;
    }
  }

  &__clip-info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.6rem;

    &__match {
      min-width: 160px;
      padding: 0.4rem;
      border-radius: 4px;
      background-color: color(white);
      font-size: 1.2rem;
    }

    &__label {
      padding: 0.4rem;
      border-radius: 4px;
      background-color: color(white);
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  &__icons {
    display: flex;
    gap: 0.8rem;
    justify-content: flex-end;
    align-items: center;

    &__icon-button {
      cursor: pointer;
    }
  }
}
