@import '../../../styles/mixins';
@import '../../../styles/functions';

.playground-bar-box {
  display: flex;
  width: 154px;
  height: 34px;
  margin: 0 4px;
  padding: 5px 6px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: color(white);
  $self: &;

  &--small {
    width: 30%;
  }

  &--show-labels {
    height: 70px;
  }

  &--color_green {
    background-color: color(green, 60);
  }

  &--color_orange {
    background-color: color(orange, 60);
  }

  &--color_red {
    background-color: color(red, 60);
  }

  &--color_white {
    width: 96px;
    padding: unset;
    background-color: color(white);
    color: color(cool-gray, 100);
    cursor: pointer;

    @include disableUserSelect();

    #{$self}__heading {
      font-size: 1.2rem;
      font-weight: bold;
      color: color(cool-gray, 100);
      text-align: center;
      text-transform: uppercase;
      line-height: 1.5;
    }

    svg {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -9px);
    }

    #{$self}__values {
      & th {
        color: color(cool-gray, 50);
      }

      & td {
        position: relative;
        line-height: normal;
        border: 1px solid color(cool-gray, 100);

        &:first-child:after {
          position: absolute;
          width: 0.5px;
          height: 100%;
          top: 0;
          right: 0;
          background-color: color(cool-gray, 100);
          content: '';
        }
      }
    }
  }

  &__heading {
    position: relative;
    color: color(white);
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    line-height: 1.5;
  }

  &__values {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0px;
    margin: 0;

    th {
      width: 50%;
      font-size: 1rem;
      text-align: center;
      font-weight: normal;
      line-height: 1.6;
    }

    td {
      border: 1px solid color(white);
      background-color: rgba(color(white), 0.2);
      font-size: 1.4rem;
      line-height: 1.57;
      text-align: center;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
      }
    }
  }
}
