@import '../../../styles/mixins';
@import '../../../styles/functions';

.table-metric-tooltip {
  display: none;
  box-sizing: border-box;
  width: 250px;
  position: absolute;
  top: calc(100% + 2px);
  left: calc(-125px + 50%);
  background-color: color(white);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(color(cool-gray, 100), 0.35);
  padding: 0.8rem 2rem;

  &--is-shown {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    z-index: 10;
  }

  &--align-to-right {
    left: calc(-250px + 100%);
  }

  &--is-last-child-right-table {
    left: -132px;
  }

  &__title {
    color: color(blue);
  }

  &__description {
    font-weight: normal;
  }
}
