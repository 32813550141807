@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.event-log-users-content-columns {
  @include cellBase();
  height: unset;
  border-bottom: unset;
  justify-content: flex-start;
  font-size: 1.4rem;

  &__access-at-cell {
    min-width: 140px;
  }
}
