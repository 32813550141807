@import '../../../styles/functions';

.video-center-column {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: color(blue);
  font-weight: 900;
  gap: 1rem;
  cursor: pointer;
}
