@import '../../../../styles/mixins';

.overview-page {
  @include tcmsPageContainer();
  padding-top: 24px;
  flex-direction: column;
  gap: 24px;

  &__hl-ec {
    display: flex;
    gap: 40px;

    &__charts {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__ep {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__charts {
      display: flex;
      gap: 40px;
    }
  }
}
