@import '../../../styles/functions';

$zoneRedColor: color(red, 60);
$zoneBlackColor: color(cool-gray, 100);

.goal-net {
  position: relative;
  $self: &;

  &--is-clickable {
    path,
    rect {
      cursor: pointer;
    }
  }

  &--variant_red {
    $variant: &;
    &#{$self}--active-zone {
      &_ur path.ur {
        fill: $zoneRedColor;
      }

      &_ul path.ul {
        fill: $zoneRedColor;
      }

      &_br path.br {
        fill: $zoneRedColor;
      }

      &_bl path.bl {
        fill: $zoneRedColor;
      }

      &_fh path.fh {
        fill: $zoneRedColor;
      }

      &_ce rect.ce {
        fill: $zoneRedColor;
      }
    }
  }

  &--variant_blue {
    $variant: &;
    &#{$self}--active-zone {
      &_ur path.ur {
        fill: url(#Gradient);
      }

      &_ul path.ul {
        fill: url(#Gradient);
      }

      &_br path.br {
        fill: url(#Gradient);
      }

      &_bl path.bl {
        fill: url(#Gradient);
      }

      &_fh path.fh {
        fill: url(#Gradient);
      }

      &_ce rect.ce {
        fill: url(#Gradient);
      }
    }
  }

  &--variant_black {
    $variant: &;
    &#{$self}--active-zone {
      &_ur path.ur {
        fill: $zoneBlackColor;
      }

      &_ul path.ul {
        fill: $zoneBlackColor;
      }

      &_br path.br {
        fill: $zoneBlackColor;
      }

      &_bl path.bl {
        fill: $zoneBlackColor;
      }

      &_fh path.fh {
        fill: $zoneBlackColor;
      }

      &_ce rect.ce {
        fill: $zoneBlackColor;
      }
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &--disable-content-pointer-events {
      pointer-events: none;
    }
  }
}
