@import '../../../styles/functions';
@import '../../../styles/mixins';

.sidebar {
  @include sidebarDefaultStyles();

  &__close {
    margin-bottom: 3.2rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1.6rem;
    font-size: 2rem;
    font-weight: bold;
  }

  &__description {
    margin-top: 0;
    margin-bottom: 3.2rem;
    font-size: 1.4rem;
    letter-spacing: 0.01em;
    line-height: 1.6;
  }

  .tips-content {
    margin-bottom: 3.2rem;
  }
}
