@import '../../../styles/mixins';

.video-center-select-list-metric {
  @include videoSelectListSharedStyles();

  &__all-button {
    width: fit-content;

    p {
      margin: 0;
      font-weight: bold;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 1.4rem;
    }
  }

  &__header-container {
    display: flex;
    gap: 16px;

    > div {
      border-right: 1px solid color(cool-gray, 20);

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }

    &__item {
      display: flex;
      height: 36px;
      flex-direction: column;
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding-right: 16px;

      strong {
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
  }

  &__list-buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    button {
      flex: 1;
    }

    &__navigate-icon {
      transform: rotate(-90deg);
    }
  }
}
