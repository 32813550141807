@import '../../../styles/functions';

.head-to-head-player {
  display: flex;
  position: relative;
  min-width: 146px;
  height: 32px;
  padding-right: 0.7rem;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-right: 0.1rem solid color(cool-gray, 60);
  font-size: 1.2rem;
  font-weight: 700;
  $self: &;

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: -1;
    content: '';
  }

  &--reversed {
    width: 32px;
    min-width: unset;
    height: auto;
    min-height: 146px;
    padding-bottom: 0.7rem;
    padding-right: unset;
    writing-mode: vertical-rl;
    border-right: none;
    border-bottom: 0.1rem solid color(cool-gray, 60);

    &:after {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: unset;
    }

    #{$self}__name {
      padding-right: unset;
      padding-bottom: 0.6rem;
    }
  }

  &--cross-color-active:after {
    background-color: color(cool-gray, 20);
  }

  &--border {
    &_right {
      border-right: 0.1rem solid color(black);
    }

    &_bottom {
      border-bottom: 0.1rem solid color(black);
    }
  }

  &__name {
    padding-right: 0.6rem;
  }
}
