@import '../../styles/functions';

.sticker {
  position: relative;
  background-color: color(cool-gray, 10);
  color: color(cool-gray, 100);

  &--size {
    &_small {
      font-size: 1.2rem;
      line-height: 1;
      border-radius: 0.2rem;
      padding: 0.4rem 0.7rem;
    }

    &_medium {
      font-size: 1.2rem;
      font-weight: 500;
      border-radius: 0.3rem;
      padding: 0.6rem 0.7rem;
    }

    &_large {
      font-size: 1.4rem;
      font-weight: bold;
      border-radius: 0.4rem;
      padding: 0.6rem 0.7rem;
    }
  }

  &--is-clickable {
    cursor: pointer;

    &:hover {
      color: color(blue);
      font-weight: bold;
    }
  }

  &--color_green {
    color: color(white);
    background-color: color(teal, 60);
  }

  &--color_red {
    color: color(white);
    background-color: color(red, 60);
  }

  &--color_violet {
    color: color(white);
    background-color: color(purple, 60);
  }

  &--color_orange {
    color: color(white);
    background-color: color(orange, 60);
  }

  &--color_white {
    background-color: color(white);
  }

  &--color_blue-active {
    background-image: linear-gradient(to bottom, color(blue, 50), color(blue, 60));
    color: color(white);
  }

  &--color_on {
    background-color: color(green, 60);
    color: color(white);
  }

  &--color_off {
    background-color: color(red, 60);
    color: color(white);
  }

  &--font-weight_normal {
    font-weight: normal;
  }

  &--font-weight_bold {
    font-weight: bold;
  }
}
