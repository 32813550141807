.game-expected-goals-stats {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  width: fit-content;
  gap: 1rem;

  &--reversed {
    align-items: flex-start;
  }
}
