@import '../../../styles/functions';
@import '../../../styles/colorMixins';
@import '../../../styles/colorVariables';

.goal-stats-box {
  display: inline-block;
  position: relative;
  border-radius: 0.8rem;
  background-color: color(white);
  cursor: pointer;
  $self: &;

  table {
    border-spacing: 0;

    tbody tr {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 1rem;
      text-align: center;

      td {
        display: flex;
        padding: unset;
        width: 47px;
        height: 22px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid color(gray, 40);
        border-radius: 0.8rem;
        font-size: 1.3rem;
        font-weight: 600;
      }
    }
  }

  &--font-weight {
    &_normal {
      table tbody tr td {
        font-weight: 400;
      }
    }

    &_medium {
      table tbody tr td {
        font-weight: 500;
      }
    }

    &_semi-bold {
      table tbody tr td {
        font-weight: 600;
      }
    }

    &bold {
      table tbody tr td {
        font-weight: 700;
      }
    }
  }

  &--has-more-elements {
    table {
      tbody {
        tr {
          td {
            border-radius: unset;
            border-left: unset;

            &:first-child {
              border-left: 1px solid color(gray, 40);
              border-radius: 0.8rem 0 0 0.8rem;
            }

            &:last-child {
              border-radius: 0 0.8rem 0.8rem 0;
            }
          }
        }
      }
    }
  }

  @include getColorClassesFromMap($playerColorMap) using ($color) {
    background-color: rgba($color, 0.2);
    color: $color;

    table tbody tr td {
      border-color: $color;

      &:first-child {
        border-left: 1px solid $color;
      }
    }
  }

  &--variant_dark {
    background-color: rgba(color(white), 0.2);
    color: color(white);

    table tbody tr td {
      border-color: color(white);

      &:first-child {
        border-color: color(white);
      }
    }

    &#{$self}--color_default {
      color: color(white);
    }
  }

  &--disable-play-icon {
    pointer-events: none;
  }

  &--is-horizontal {
    table tbody tr {
      display: flex;
      width: 56px;
      height: 47px;
      flex-direction: column;
      font-size: 1.4rem;
      border: 1px solid color(gray, 40);
      border-radius: 0.8rem;

      td {
        width: 100%;

        &:first-child {
          border: none;
          border-radius: unset;
        }

        &:last-child {
          border: none;
          border-top: 1px solid color(gray, 40);
          border-radius: unset;
        }
      }
    }
  }

  &__play-icon {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    background-color: rgba(color(white), 0.4);
  }

  &:hover #{&}__play-icon {
    display: flex;
  }
}
