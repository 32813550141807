@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.defined-templates-checkbox-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  hr {
    background-color: colorTcms(slate, 300);
    margin-bottom: 1.2rem;
  }

  &__option {
    margin-bottom: 1.6rem;

    &__sub-option {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1.6rem;
    }
  }
}
