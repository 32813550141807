@import '../../styles/functions';

.sub-navigation {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  padding-top: 1.2rem;
  background-color: color(cool-gray, 10);
}
