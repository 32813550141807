@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.defined-templates-section {
  display: flex;
  flex-direction: column;
  width: 488px;

  h3 {
    color: colorTcms(blue, 700);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
  }

  &__edit-type-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .tcms-button {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &__add-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 13px;
    color: colorTcms(blue, 700);
    font-size: 1.4rem;
    gap: 0.4rem;
    cursor: pointer;
    @include disableUserSelect();

    &:hover {
      text-decoration: underline;
    }
  }

  &--size_medium {
    width: 392px;
  }

  &--size_small {
    width: 296px;
  }
}
