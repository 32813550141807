@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.graphic-overview-content {
  margin: 3.2rem 0;
  flex-direction: column;
  gap: 3.2rem;
  @include pageContainer();

  &__heatmap-title {
    font-size: 1.6rem;
    min-width: 16rem;
    margin-top: 0;
    text-align: center;
    white-space: nowrap;
  }

  &__buttons-container {
    display: flex;
    justify-content: center;
    gap: 296px;

    button {
      width: 228px;
    }
  }

  &__goals-story {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    text-align: center;
    white-space: nowrap;
    align-items: center;

    &__title {
      margin: 0;
      font-size: 1.6rem;
    }
  }

  &__shot-flow-graph {
    display: flex;
    position: relative;
    margin: 3.2rem 0;
    flex-direction: column;

    &__maps {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h3 {
        margin: 0 4.5rem;
        font-size: 1.6rem;
        font-weight: 700;
      }
    }

    &__buttons {
      display: flex;
      margin-top: 3.2rem;
      justify-content: center;
      align-items: center;
      gap: 1.6rem;

      .button {
        min-width: 23.2rem;
      }
    }
  }
}
