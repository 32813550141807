@import '../../../styles/functions';

.login-button {
  display: flex;
  width: 100%;
  padding: 1.6rem 0;
  flex: 1;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background-color: colorTcms(dark-blue, 650);
  color: colorTcms(white);
  font-size: 1.4rem;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: colorTcms(sky, 500);
  }
}
