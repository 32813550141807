@import '../../../styles/colorMixins';
@import '../../../styles/colorVariables';
@import '../../../styles/functions';

.games-select-item-content {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: bold;

    &__date {
      font-size: 1rem;
      font-weight: normal;
      color: color(cool-gray, 50);
    }
  }
}
