@import '../../../styles/functions';
@import '../../../styles/mixins';

.download-management {
  @include defaultContentStyles();

  &__table-container {
    min-height: 520px;
    margin-bottom: 1.6rem;
    overflow: auto;

    &__table {
      border-collapse: collapse;
      width: 100%;
      min-width: 900px;

      th {
        text-align: left;
        padding: 0.8rem;
        font-size: 1.2rem;
        background-color: color(black);
        color: color(white);
      }
    }

    &__pagination {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
