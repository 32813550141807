@import '../../../../../../styles/mixins';

.list-hokej-player {
  @include tcmsOverviewComponentsStyle();
  height: 330px;

  &__overview-card-list {
    display: flex;
    gap: 15px;
    padding: 16px 16px 8px;

    > div {
      width: 206px;
    }
  }
}
