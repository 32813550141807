@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.compare-players-content-component {
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: color(cool-gray, 10);

  @include inputsAndCardsChildrenStyles();
  gap: 3.25rem;

  &__inputs {
    background-color: color(white);
    padding-bottom: 3.25rem;

    &__control-box {
      @include inputsAndCardsChildrenStyles();

      > div {
        flex-grow: 1;
      }
    }
  }

  &__cards {
    @include inputsAndCardsChildrenStyles();
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .content-info-box {
    padding-left: 15px;
    padding-right: 15px;
  }
}
