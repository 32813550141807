.inner-modal {
  position: absolute;
  top: 20px;
  bottom: 20px;
  padding: 2rem;
  border-radius: 16px;
  width: 512px;
  box-sizing: border-box;
  background-color: white;
  box-shadow:
    0 2px 6px 0 rgba(18, 22, 25, 0.1),
    0 2px 16px 0 rgba(18, 22, 25, 0.25);

  &--position_left {
    left: 20px;
  }

  &--position_right {
    right: 20px;
  }
}
