@import '../../../../../../styles/mixins';

.report-table-box {
  width: 100%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1554px;

  @include media-query(xxl) {
    width: 1338px;
  }

  @include media-query(xl) {
    width: 1187px;
  }

  &__table-label {
    font-weight: bold;
    margin-bottom: 1.6rem;
  }

  &__table {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
  }

  &__export-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.4rem;

    .button {
      width: 16.8rem;
    }
  }
}
