@import '../../../styles/functions';
@import '../../../styles/mixins';

.playground-shot-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 264px;
  height: 158px;
  border-radius: 10px;
  box-shadow:
    0 1px 2px 0 rgba(color(cool-gray, 100), 0.1),
    0 2px 12px 0 rgba(color(cool-gray, 100), 0.25);
  background-color: color(white);
  transform: translate(-152px, -205px);
  z-index: 1000;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 12px 16px;
  $self: &;
  @include disableUserSelect();

  &--active-zone {
    height: 245px;
    transform: translate(-152px, -292px);
  }

  &--show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & #{$self}__tooltip {
      font-weight: bold;
    }

    & #{$self}__button {
      width: 100%;
      margin-top: 7px;

      button {
        width: 100%;
      }
    }
  }

  &__goal {
    margin: 8px 0;
  }

  &:after {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: calc(50% - 5px);
    bottom: -9px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid color(white);
    content: '';
  }
}
