@import '../../../styles/functions';

.change-password {
  max-width: 800px;
  margin-inline: auto;

  h2 {
    margin: 0 0 2rem 0;
    text-align: center;
    color: color(cool-gray, 60);
    font-size: 2.8rem;
  }

  &__form {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.6rem;

    .button {
      margin-left: auto;
      width: 232px;
    }
  }
}
