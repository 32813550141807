@import '../../styles/functions';
@import '../../styles/colorVariables.scss';

.login-page {
  width: 100%;
  min-height: 100vh;
  background: colorTcms(slate, 100);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: colorTcms(slate, 400);

  &__content {
    display: flex;
    width: 480px;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 8px;
    border: 1px solid colorTcms(slate, 100);
    box-shadow: 0px 16px 40px rgba(colorTcms(slate, 500), 0.15);
    background-color: colorTcms(white);
    padding: 40px;
    box-sizing: border-box;

    &__form {
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;
      gap: 1.2rem;

      &__app-state {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      &__language {
        color: colorTcms(slate, 950);

        label {
          display: block;
          padding-bottom: 0.4rem;
          color: colorTcms(slate, 950);
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1.8rem;
        }
      }

      .login-button {
        margin-top: 1.2rem;
      }

      > div {
        width: 100%;
      }
    }

    &__version {
      margin-top: 2.4rem;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    &__changelog {
      margin-top: 0.4rem;
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__login-footer {
    margin-top: 40px;
    display: flex;
    width: 480px;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 8px;
    font-size: 1.2rem;
    line-height: 1.8rem;

    hr {
      margin-top: 40px;
      background-color: colorTcms(slate, 300);
    }

    a {
      color: colorTcms(slate, 400);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &__ec-app {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    &__copyright {
      margin-top: 16px;
    }
  }
}
