.face-off-playground {
  position: relative;
  width: 1024px;
  height: 512px;

  &__canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
