.hokej-player-management-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  h1 {
    font-size: 2rem;
  }
}
