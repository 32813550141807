@import '../../../styles/functions';

.head-to-head-team-divider {
  position: relative;
  width: 146px;
  height: 146px;
  box-sizing: border-box;
  border-right: 1px solid color(cool-gray, 60);
  border-bottom: 1px solid color(cool-gray, 60);

  &:after {
    display: block;
    position: absolute;
    width: 205px;
    height: 2px;
    background: color(cool-gray, 60);
    transform: rotateZ(45deg);
    top: 73px;
    left: -30px;
    content: '';
  }

  &__home-team {
    position: absolute;
    width: 50px;
    left: 0;
    bottom: 0;
  }

  &__away-team {
    position: absolute;
    width: 50px;
    top: 0;
    right: 0;
  }

  &__home-team-name {
    position: absolute;
    bottom: 15px;
    right: 50px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    color: color(black);
  }

  &__away-team-name {
    position: absolute;
    bottom: 58px;
    right: 8px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    color: color(black);
    transform: rotateZ(90deg);
  }
}
