@import '../../styles/functions';

.account-management {
  &__navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    padding-top: 1.2rem;
    background-color: color(cool-gray, 10);
  }

  &__sub-page {
    display: flex;
    padding: 4rem 0;
    flex-direction: column;
    justify-content: center;
  }
}
