@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.head-to-head-content {
  @include defaultContentStyles();
  padding-bottom: 80px;

  &__filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.8rem;

    .select-input {
      min-width: 200px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 2rem;
      color: color(black);
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.25;
    }
  }
}
