.game-expected-goals {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 3.6rem;

  &__title {
    font-size: 1.6rem;
    min-width: 16rem;
    text-align: center;
    white-space: nowrap;
  }

  &__team {
    display: flex;
    width: 40%;
    flex-flow: row nowrap;
    align-items: center;
    gap: 3.8rem;

    &:first-child {
      justify-content: flex-end;
    }
  }
}
