@import '../../styles/mixins';
@import '../../styles/functions';

.search-input {
  position: relative;

  &__input-container {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__options {
    display: none;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid color(gray, 30);
    border-top: 0;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    @include filterStyle();

    &--visible {
      z-index: 100;
      display: block;
      overflow: hidden;
    }

    &--scrollable {
      overflow-y: scroll;
      max-height: 500px;
    }
  }

  &__close-icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
  }
}
