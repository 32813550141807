@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.access-log-users-content-columns {
  @include cellBase();
  height: unset;
  border-bottom: unset;
  justify-content: flex-start;
  font-size: 1.4rem;

  &__username-cell {
    justify-content: flex-start;
    font-weight: normal;
  }

  &__ip-cell a {
    text-decoration: none;
    color: colorTcms(blue, 700);
  }
}
