@import '../../../styles/variables';

.game-score {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: fit-content;
  gap: 0.4rem;

  &__score {
    margin: 0;
    font-size: 4rem;
  }

  &__thirds {
    margin: 0;
    font-size: 1.2rem;
  }
}
