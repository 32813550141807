@import '../../../styles/functions';

.language-controller {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    filter: grayscale(0.8);
  }
}
