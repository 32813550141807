@import '../../styles/functions';

.scroll-button {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid color(gray, 40);
  border-radius: 8px;
  background-color: color(white);
  $self: &;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--is-left {
    transform: rotateY(180deg);
  }

  &--variant_tcms {
    width: 24px;
    height: 24px;
    border: 0;
    background-color: transparent;

    line {
      stroke: colorTcms(black);
    }

    &#{$self}--disabled {
      pointer-events: none;
      opacity: 1;

      line {
        stroke: colorTcms(slate, 500);
      }
    }
  }
}
