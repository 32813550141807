@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.videomaps-zone-exits-content {
  @include shotsAndPassesContentsStyles();

  &__main {
    @include shotsAndPassesMainClassStyles();

    &__form-box__save-button button {
      width: 100%;
    }
  }
}
