@import '../../../styles/functions';
@import '../../../styles/mixins';

.tcms-item {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 1.2rem;
  align-items: center;
  border-radius: 8px;
  gap: 1.2rem;
  background-color: colorTcms(slate, 100);
  box-sizing: border-box;

  &--is-clickable {
    cursor: pointer;

    &:hover {
      background-color: colorTcms(slate, 200);
    }
  }

  &--selected {
    background-color: colorTcms(slate, 300);

    &:hover {
      background-color: colorTcms(slate, 300);
    }
  }

  &__label {
    width: 100%;
    color: colorTcms(slate, 950);
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
}
