@import '../../../styles/functions';

.game-expected-win {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__bar {
    position: relative;
    width: 528px;
    height: 32px;
    border-radius: 0.8rem;
    margin: 0 1.6rem;

    .bar {
      height: 100%;
    }
  }

  &__percentile {
    width: 60px;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__text {
    margin: 0 1rem;
    font-size: 1.6rem;
    font-weight: 700;
  }
}
