$stickers-padding: 0.4rem;

.formation-column {
  display: flex;
  flex-direction: column;
  gap: $stickers-padding;
  padding: $stickers-padding 0;

  &__position {
    display: flex;
    gap: $stickers-padding;
  }
}
