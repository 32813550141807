@import '../../../styles/functions';
@import '../../../styles/colorMixins';
@import '../../../styles/colorVariables';

.goal-stats-box-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap: 0.3rem;
  border-radius: 1rem;
  background-color: color(white);
  box-shadow:
    0px 1px 2px rgba(color(cool-gray, 100), 0.1),
    0px 2px 12px rgba(color(cool-gray, 100), 0.25);
  $self: &;

  &--is-horizontal &__tables {
    flex-direction: row;
  }

  &--disable-box-shadow {
    box-shadow: none;
  }

  &__label {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 700;
    font-style: bold;
    text-transform: uppercase;
  }

  &__tables {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;

    &__table {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      &__labels {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        font-size: 1rem;
        text-align: center;

        span {
          width: 49px;
        }
      }

      &__goal-stats-box {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @include getColorClassesFromMap($playerColorMap) using ($color) {
    background-color: $color;
    color: color(white);
  }

  &--color {
    &_default {
      color: color(cool-gray, 100);

      &__label {
        color: color(cool-gray, 100);
      }
    }

    &_black {
      background-color: color(cool-gray, 100);
      color: color(white);
    }
  }
}
