@import '../../styles/functions';
@import '../../styles/mixins';

.position-circle {
  @include letterCircleBaseStyles();

  &--team_away {
    border-color: color(orange, 60);
    color: color(orange, 60);
  }

  &--team_home {
    border-color: color(purple, 80);
    color: color(purple, 80);
  }

  &--has-white-background {
    background-color: color(white);
  }
}
