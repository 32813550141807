@import '../../styles/mixins';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.on-off-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 2.4rem;
  width: 3.2rem;
  border: 1px solid;
  border-radius: 0.4rem;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  $self: &;

  @include getColorClassesFromMap($playerWithOnOffColorMap) using ($color) {
    color: $color;
    border-color: $color;
  }

  &--color_black {
    color: color(cool-gray, 100);
    border-color: color(gray, 40);
  }

  &--is-on {
    color: color(white);

    @include getColorClassesFromMap($playerWithOnOffColorMap, $self) using ($color) {
      background-color: $color;
      border-color: $color;
    }

    &#{$self}--color_black {
      background-color: color(cool-gray, 100);
      border-color: color(cool-gray, 100);
    }
  }
}
