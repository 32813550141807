@import '../../styles/mixins';
@import '../../styles/functions';

.input {
  @include defaultInputsStyle();
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: none;
  width: inherit;
  $self: &;

  input {
    font-family: $default-font-family;
    font-size: 1.4rem;
    box-sizing: border-box;
    border: 0px;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    outline: none;

    &::placeholder {
      /* Chrome, Firefox, Safari 10.1+ */
      font-family: $default-font-family;
      color: color(cool-gray, 60);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: $default-font-family;
      color: color(cool-gray, 60);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-family: $default-font-family;
      color: color(cool-gray, 60);
    }
  }

  &--not-icon {
    padding: 0;

    input {
      padding: 0 0.8rem;
      border-radius: inherit;
    }
  }

  &--disable-appearance {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &:focus-within {
    @include outlineOnFocus(color(blue));
  }

  &--variant_filter {
    @include filterStyle();
    width: inherit;
  }

  &--variant_tcms {
    @include tcmsInputStyle();
    height: 40px;
    width: inherit;
    transition: border 0.25s;

    &:hover {
      border: 1px solid colorTcms(slate, 400);
    }

    &:focus-within {
      border: 1px solid colorTcms(blue, 700);
      outline: 3px solid colorTcms(blue, 100);
      outline-offset: 0px;
    }
  }

  &--error {
    @include borderError();

    &:focus-within {
      outline: 0;
    }
  }
}
