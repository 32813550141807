@import '../../styles/functions';

.ratio-bar {
  height: 4rem;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    color: color(cool-gray, 100);
  }

  &__bar {
    height: 4rem;
    position: absolute;
    display: flex;
    align-items: center;

    &--left {
      left: 0;
      justify-content: flex-start;
      background: linear-gradient(to right, color(blue, 50), color(blue, 60));

      p {
        margin-left: 0.8rem;
      }
    }

    &--right {
      right: 0;
      justify-content: flex-end;
      background: linear-gradient(to left, color(red, 60), color(red, 70));

      p {
        margin-right: 0.8rem;
      }
    }
  }

  &__value {
    color: color(white);
    font-weight: bold;
    font-size: 1.6rem;
  }
}
