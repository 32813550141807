@import '../../styles/mixins';
@import '../../styles/functions';

.app-view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &--disable-all {
    pointer-events: none;
  }
}

.navigation-bar {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  box-shadow:
    0 0.2rem 1.2rem 0 rgba(color(cool-gray, 100), 0.08),
    0 0.2rem 0.2rem 0 rgba(color(cool-gray, 100), 0.04);

  &__container {
    position: relative;
    height: 7.2rem;
    justify-content: space-between;
    align-items: center;
    @include pageContainer();

    &__dashboard-link {
      display: flex;

      &__logo {
        width: 48px;
        height: 48px;
      }
    }

    &__item-list {
      display: flex;
      gap: 2.6rem;
      align-self: flex-end;

      @include media-query(m) {
        gap: 1.2rem;
      }
    }

    &__nav-bar-buttons-list {
      display: flex;
      gap: 0.8rem;
    }
  }
}
