@import '../../../styles/functions';
@import '../../../styles/paletteTcms';

.user-log-column-header {
  display: flex;
  position: relative;
  align-items: center;
  min-width: 90px;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.4rem;
  color: colorTcms(slate, 500);
  $self: &;

  &--is-sortable {
    cursor: pointer;
  }

  &--color_blue {
    color: colorTcms(white);
  }

  &__app-logo {
    position: absolute;
    right: -25%;
    top: -64px;
    transform: translateX(25%);
    pointer-events: none;
  }

  &__arrows {
    visibility: hidden;

    &--sorted {
      visibility: visible;
    }

    &__arrow {
      path#caret-up-down-up,
      path#caret-up-down-down {
        fill: colorTcms(slate, 200);
      }

      &--sort-direction_asc path#caret-up-down-up {
        fill: colorTcms(slate, 500);
      }

      &--sort-direction_desc path#caret-up-down-down {
        fill: colorTcms(slate, 500);
      }
    }
  }
}
