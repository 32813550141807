@import '../../styles/variables';

.widget-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__header {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.33;
  }

  &__description {
    width: 376px;
    max-width: 100%;
    margin: 0rem auto 1.6rem;
    font-size: 1.4rem;
    line-height: 1.57;
    text-align: center;
  }

  &__inline {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: 0.8rem;

    > div,
    > button {
      width: 50%;
    }

    > button {
      margin-top: $caption-height;
    }
  }
}
