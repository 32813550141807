@import '../../styles/functions';

.cropped-time {
  position: relative;
  display: flex;

  &--is-edited {
    path {
      fill: color(gray, 80);
    }
  }

  &--is-clickable {
    cursor: pointer;
  }

  .cropped-tooltip {
    position: absolute;
    background-color: color(gray, 90);
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    white-space: nowrap;
    color: color(white);
    top: -4.5px;
    right: 25px;
    display: none;
    font-size: 1.2rem;

    &::after {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px;
      border-color: transparent transparent color(gray, 90);
      position: absolute;
      top: calc(50% - 4px);
      right: -10px;
      transform: rotate(90deg);
      content: '';
    }
  }

  &:hover,
  &:focus {
    .cropped-tooltip {
      display: block;
    }
  }
}
