@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.videomaps-shots-content {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  &__main {
    @include shotsAndPassesMainClassStyles();
  }

  &__minimaps {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 3rem 2rem 3rem;
    background-color: color(cool-gray, 10);

    &__toggle-items {
      display: flex;
      width: 995px;
      flex-direction: column;
      margin: 1.875rem auto 1rem;

      .caption {
        margin-bottom: 0.25rem;
      }

      .toggle-select {
        gap: 1rem;

        .button {
          width: 232px;
        }
      }
    }

    &__content {
      display: flex;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2.5rem;
      margin-top: 2.5rem;

      &__item {
        display: flex;
        height: auto;
        margin-bottom: 45px;
        flex-direction: column;
        justify-content: center;

        &__title {
          width: 462px;
          text-align: center;
          font-size: 1.6rem;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          margin-bottom: 6px;
        }

        .playground {
          position: relative;
        }
      }
    }

    &__save-button {
      display: flex;
      justify-content: center;
      margin-top: 32px;

      button {
        width: 232px;
      }
    }
  }
}
