.goal-net-shootout {
  position: relative;
  width: 760px;
  height: 512px;

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}
