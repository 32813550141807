@import '../../../styles/functions';

.goals-overview-row {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.6rem;
  border-bottom: 0.1rem solid color(cool-gray, 20);
  animation: fade-in 300ms ease-in-out forwards;

  $self: &;

  &--away {
    flex-flow: row-reverse nowrap;
  }

  &__data {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1.2rem;
    padding: 1.8rem 0;
    flex: 1;
    $data: &;

    p {
      font-size: 1.4rem;
      margin: 0;

      &#{$data}__player {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      &#{$data}__count-of-players {
        width: 34px;
        text-align: center;
      }

      &#{$data}__time {
        width: 34px;
      }
    }

    svg {
      cursor: pointer;
    }

    #{$self}--away & {
      flex-flow: row-reverse nowrap;

      p {
        &#{$data}__player {
          justify-content: flex-start;
        }
      }
    }
  }

  &__score {
    position: relative;
    background-color: color(cool-gray, 10);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: grid;
    place-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.2rem;
      height: 1.5rem;
      background-color: color(cool-gray, 10);
      z-index: 2;
    }

    &--is-first-row {
      &::before {
        display: none;
      }
    }
  }

  &:after {
    position: absolute;
    width: 70px;
    height: calc(100% + 0.1rem);
    top: 0;
    left: 50%;
    background-color: color(white);
    transform: translateX(-50%);
    content: '';
    z-index: 1;
  }
}
