.video-center-input {
  &__title {
    margin: 0 0 0.4rem 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__input {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.8rem;
  }

  &__button {
    cursor: pointer;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
