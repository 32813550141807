@import '../../../styles/functions';

.chart-legend {
  display: flex;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: colorTcms(slate, 500);
  gap: 8px;

  &__item {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: colorTcms(cyan, 500);

    &--team {
      background-color: colorTcms(blue, 700);
    }

    &--media {
      background-color: colorTcms(fuchsia, 600);
    }
  }
}
