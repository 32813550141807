@import '../../styles/functions';

.loading-component {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.6rem;
  z-index: 100000;

  svg {
    animation: spin 2s linear infinite;
    animation-direction: reverse;
  }

  span {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    color: color(white);
  }

  &--absolute-center {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  &--horizontal-margin {
    margin-top: 140px;
    margin-bottom: 140px;
  }

  &--size {
    &_large {
      width: 18rem;
      height: 18rem;
      gap: 2.5rem;

      svg {
        width: 6.4rem;
        height: 6.4rem;
      }
    }

    &_medium {
      width: 9rem;
      height: 9rem;
      gap: 0.5rem;

      svg {
        width: 3.2rem;
        height: 3.2rem;
      }
    }

    &_small {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 0.8rem;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  &--background-color {
    &_dark {
      background-color: rgba(color(cool-gray, 100), 0.85);
    }

    &_gray {
      background-color: color(gray, 20);

      span {
        color: rgba(color(cool-gray, 100), 0.85);
      }

      path {
        fill: rgba(color(cool-gray, 100), 0.85);
      }
    }

    &_transparent {
      background-color: transparent;

      span {
        color: rgba(color(cool-gray, 100), 0.85);
      }

      path {
        fill: rgba(color(cool-gray, 100), 0.85);
      }
    }
  }
}
