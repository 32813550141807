@import '../../../styles/mixins';

.video-center-select-list {
  @include videoSelectListSharedStyles();

  &__all-button {
    width: fit-content;

    p {
      margin: 0;
      font-weight: bold;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 1.4rem;
    }
  }
}
