@import '../../../../../../styles/mixins';

.hokej-player-tracking-filter {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__main-part {
    @include tcmsFilterStyles();
  }
}
