@import '../../../styles/functions';

.switch {
  width: 40px;
  height: 24px;
  cursor: pointer;
  $self: &;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__toggle-wrapper {
    position: relative;
    background-color: colorTcms(slate, 300);
    width: 40px;
    height: 24px;
    border-radius: 12px;
    transition: background-color 0.25s;

    &--checked {
      background-color: colorTcms(blue, 700);
    }

    &__selector {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      background-color: colorTcms(white);
      transition: left 0.25s ease;
      border-radius: 50%;
      box-shadow: 0px 2px 2px rgba(colorTcms(slate, 400), 0.5);

      &--checked {
        left: calc(100% - 20px);
        box-shadow: 0px 2px 2px colorTcms(blue, 800);
      }
    }
  }
}
