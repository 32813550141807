@import './functions';

// Bars
$bar-away: linear-gradient(to bottom, color(orange, 60), color(orange, 70));
$bar-home: linear-gradient(to bottom, color(purple, 80), color(purple, 90));
$bar-green: linear-gradient(to bottom, color(green, 70), color(green, 60));
$bar-orange: linear-gradient(to bottom, color(orange, 70), color(orange, 60));
$bar-red: linear-gradient(to bottom, color(red, 70), color(red, 60));

$playerColorMap: (
  color_red: color(red, 60),
  color_purple: color(purple, 60),
  color_cyan: color(cyan, 60),
  color_teal: color(teal, 60),
  color_orange: color(orange, 60),
);

$onOffColorMap: (
  color_off: color(red, 60),
  color_on: color(green, 60),
  color_blue: color(blue, 60),
);

$playerWithOnOffColorMap: map-merge($playerColorMap, $onOffColorMap);

$playerColorStringsMap: (
  color_red: red,
  color_purple: purple,
  color_cyan: cyan,
  color_teal: teal,
  color_orange: orange,
);

$onOffColorStringsMap: (
  color_off: red,
  color_on: green,
  color_blue: blue,
);

$playerWithOnOffColorStringsMap: map-merge($playerColorStringsMap, $onOffColorStringsMap);
