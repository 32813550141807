@import '../../../styles/functions';

.users-modal {
  &__header {
    display: flex;
    width: 400px;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;

    &__icon {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }

    h2 {
      width: 100%;
      margin: 0;
      color: colorTcms(blue, 700);
      text-align: center;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__body {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.8rem;
    margin-top: 2rem;
  }

  &__button {
    width: fit-content;
    margin-left: auto;
    margin-top: 0.8rem;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
  }
}
