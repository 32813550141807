@import '../../../styles/functions';

.game-button {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 128px;
  width: 128px;
  height: 72px;
  border-radius: 8px;
  border: 1px solid color(gray, 40);
  padding: 0.6rem 0;
  cursor: pointer;
  background-color: color(white);

  &__date {
    font-size: 1.1rem;
  }

  &__teams {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__score {
    font-size: 2rem;
    font-weight: bold;
  }

  &--is-active {
    color: color(white);
    background: linear-gradient(to bottom, color(blue, 50), color(blue, 60));
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
