@import '../../../styles/functions';

.metric-button {
  width: 232px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 0.2rem;
  background-color: color(cool-gray, 10);
  $self: &;

  &--color_green {
    background: color(green, 10);
  }

  &--color_red {
    background: color(red, 20);
  }

  &--is-active {
    &#{$self}--color_gray {
      background: linear-gradient(to bottom, color(blue, 50), color(blue, 60));
    }

    &#{$self}--color_green {
      background: linear-gradient(to bottom, color(green, 40), color(green, 60));
    }

    &#{$self}--color_red {
      background: linear-gradient(to bottom, color(red, 60), color(red, 70));
    }

    &#{$self}--color_black {
      background: linear-gradient(to bottom, color(cool-gray, 60), color(cool-gray, 100));
    }
  }

  &__button {
    flex: 1;
    padding: 0;
    line-height: 24px;
    text-align: center;
    position: relative;
    letter-spacing: normal !important;
    border: none;
    cursor: pointer;
    background: transparent;
    $self: &;

    &--is-active {
      color: color(white);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
