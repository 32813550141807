@import '../../../../styles/mixins';
@import '../../../../styles/functions';
@import '../../../../styles/colorVariables';

.playground-shots-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include disableUserSelect();

  &__shadow-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__shot {
    position: absolute;
    display: flex;
    width: 22px;
    height: 22px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    border-radius: 50%;
    background-color: color(white);
    transform: translate(-11px, -11px);
    z-index: 1;
    cursor: pointer;
    $selfShot: &;

    &:before {
      display: block;
      box-sizing: border-box;
      position: relative;
      width: 18px;
      height: 18px;
      border: 2px solid color(cool-gray, 100);
      border-radius: 50%;
      background-color: color(white);
      content: '';
    }

    &--is-goal {
      z-index: 2;
    }

    &--color_0 {
      &:before {
        background-color: rgba(color(cool-gray, 100), 0.5);
      }

      &#{$selfShot}--is-goal:before {
        background: color(blue) url('../../../../assets/icons/goal.svg') center no-repeat !important;
      }
    }

    @include getColorClassesFromMap($playerColorMap) using ($color) {
      &:before {
        background-color: $color;
        border: 2px solid rgba(color(black), 0.2);
      }

      &#{$selfShot}--is-goal:before {
        background: color(black) url('../../../../assets/icons/goal.svg') center no-repeat !important;
        border: 3px solid $color;
      }
    }

    &:focus {
      z-index: 3;
    }
  }
}
