@import '../../../styles/colorMixins';
@import '../../../styles/colorVariables';
@import '../../../styles/functions';

$circleSize: 24px;
$circleBorderSize: 2px;

.games-select-item-actions {
  display: flex;
  position: absolute;
  width: 100%;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  gap: 0.6rem;
  transition: width 0.3s;

  &--is-selected {
    cursor: pointer;
  }

  &--has-more-circles:hover &__circle-wrapper {
    width: calc(100% - 0.6rem - 16px);
    background-color: color(white);
    transform: unset !important;

    &__circle {
      left: 0;

      @for $i from 1 through 4 {
        &--index_#{$i} {
          left: calc($circleSize * $i);
        }
      }
    }
  }

  &__circle-wrapper {
    display: flex;
    position: relative;
    width: $circleSize;
    height: $circleSize;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 0;
    transition: 0.3s ease-in-out;

    &__circle {
      display: flex;
      position: absolute;
      width: calc($circleSize - 2 * $circleBorderSize);
      height: calc($circleSize - 2 * $circleBorderSize);
      left: 0px;
      align-items: center;
      justify-content: center;
      border: $circleBorderSize solid color(white);
      border-radius: 100%;
      background-color: color(cool-gray, 20);
      font-size: 1.2rem;
      font-weight: 500;
      transition: left 0.3s;

      @for $i from 1 through 4 {
        &--index_#{$i} {
          left: calc($circleSize / 3 * $i);
          z-index: -$i;
        }
      }

      @include getColorClassesFromMap($playerColorMap) using ($color) {
        background-color: $color;
        color: color(white);
        font-size: 1.1rem;
      }

      &--color_default {
        width: $circleSize;
        height: $circleSize;
        border: none;
      }
    }
  }
}
