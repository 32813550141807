.shot-flow-graph-axis {
  position: absolute;
  width: calc(100% - 34px);
  left: 34px;
  bottom: 0;
  padding-top: 0.4rem;
  font-size: 1rem;
  font-weight: bold;
  $axis: &;

  &__label {
    position: absolute;
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
  }

  &--is-yaxis {
    top: 280px;
    left: -224px;
    bottom: unset;
    transform: rotateZ(-90deg);

    #{$axis}__label {
      top: -2.5rem;
    }

    #{$axis}__item {
      position: absolute;
      top: -4px;
      right: 0px;
      left: unset;
      transform: translateX(4.4px);
    }
  }

  &__item {
    position: absolute;
    left: 0;
    top: -25px;
  }
}
