@import '../../../styles/functions';

.metrics-section-circle {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: color(magenta, 50);
  color: color(white);
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;

  &--on-ice {
    background-color: color(teal, 40);
  }
}
