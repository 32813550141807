@import '../../styles/variables';
@import '../../styles/functions';

.logo-circle {
  box-shadow:
    0px 2px 12px rgba(color(cool-gray, 100), 0.08),
    0px 2px 2px rgba(color(cool-gray, 100), 0.04);
  background-color: color(white);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: grid;
  place-items: center;

  &__img-wrapper {
    width: 48px;
    height: 48px;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
