@import '../../../styles/functions';

.hokej-player-management {
  max-width: 800px;
  margin-inline: auto;

  thead {
    tr {
      background: linear-gradient(to bottom, color(cool-gray, 10), color(cool-gray, 20));

      th {
        padding: 0.8rem;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
  }

  &__table-container {
    overflow-x: auto;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    min-width: 700px;
  }
}
