@import '../../../styles/functions';
@import '../../../styles/mixins';

.overview-card {
  box-sizing: border-box;
  padding: 17px 10px;
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  $self: &;

  &__title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    color: color(cool-gray, 60);
  }

  &__value {
    margin: 0;
    font-size: 1.4rem;
    color: color(cool-gray, 60);
  }

  &--variant_default {
    background-color: color(cool-gray, 10);
  }

  &--variant_gradient {
    border-radius: 8px;
    @include tcmsGrayGradient();
  }

  &--variant_gradient {
    #{$self}__value {
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      line-height: 4rem;
      color: colorTcms(blue, 700);
    }

    #{$self}__label {
      color: colorTcms(slate, 500);
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center;

      span {
        font-weight: bold;
        text-wrap: nowrap;
      }
    }
  }
}
