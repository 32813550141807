@import '../../../styles/functions';

.summary-pie-chart {
  box-sizing: border-box;
  height: 80px;
  display: flex;
  gap: 16px;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: colorTcms(slate, 500);

  &__legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1.4rem;
      line-height: 2.2rem;
      font-weight: bold;
      color: colorTcms(blue, 700);
    }

    &--type_media {
      span {
        color: colorTcms(fuchsia, 600);
      }
    }
  }
}
