.basic-player-info {
  letter-spacing: 0.01em;
  line-height: 1.6;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.4rem;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__stats {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    gap: 6px;
  }
}
