@import '../../../styles/functions';
@import '../../../styles/mixins';

.video-center-modal {
  min-width: 1512px;
  box-sizing: border-box;

  @include media-query(xxl) {
    min-width: 1312px;
  }

  @include media-query(xl) {
    min-width: 1152px;
  }

  @include media-query(l) {
    max-width: 1000px;
    min-width: unset;
  }

  &__content {
    display: grid;
    grid-template-columns: 0.85fr 1fr;
    gap: 2rem;
    margin-top: 2rem;

    @include media-query(l) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  &__video-container {
    display: flex;
    flex-flow: column nowrap;
  }

  &__video {
    display: flex;
    flex-flow: column nowrap;
    background-color: color(cool-gray, 10);
    border-radius: 8px;
    overflow: hidden;
    flex: 1;

    iframe {
      border: 0;
    }
  }
}
