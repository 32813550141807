@import '../../../styles/mixins';
@import '../../../styles/functions';

.app-view-tcms {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: colorTcms(slate, 100);

  &--disable-all {
    pointer-events: none;
  }
}

.navigation-container {
  background-color: colorTcms(white);
  box-shadow: 0 2px 8px 0 rgba(colorTcms(slate, 500), 0.2);
}

.navigation-bar-tcms {
  margin: 0 auto;
  position: relative;
  height: 94px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @include tcmsPageContainer();

  @include media-query(m) {
    padding: 0 1rem;
  }

  &__dashboard-link {
    display: flex;
    text-decoration: none;
    color: inherit;

    button {
      @include tcmsButton();
      width: 216px;
      border: 1px solid colorTcms(blue, 700);
      background-color: colorTcms(white);
      color: colorTcms(blue, 700);

      &:hover {
        background-color: colorTcms(blue, 100);
      }

      svg {
        position: absolute;
        right: 30px;
      }
    }
  }

  &__item-list {
    display: flex;
    gap: 40px;
    align-self: center;

    @include media-query(m) {
      gap: 1.2rem;
    }
  }
}
