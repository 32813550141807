@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.defined-templates-checkboxes {
  display: flex;
  flex-direction: column;
  width: 584px;

  h3 {
    color: colorTcms(blue, 700);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
  }

  &__edit-type-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .tcms-button {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  hr {
    background-color: colorTcms(slate, 300);
  }

  &__save-button-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    color: colorTcms(blue, 700);
    font-size: 1.4rem;
    cursor: pointer;
    @include disableUserSelect();

    &__button {
      display: flex;
      align-items: center;
      gap: 0.4rem;
    }
  }

  @include media-query(xxl) {
    width: 484px;
  }

  @include media-query(xl) {
    width: 412px;
  }

  @include media-query(l) {
    width: 100%;
  }
}
