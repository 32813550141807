@import '../../../../styles/mixins';

.playground-zone-content {
  position: relative;
  width: 100%;
  height: 100%;
  $self: &;

  &--show-boxes {
    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      top: 175px;
    }

    #{$self}__total-box {
      top: 420px;
    }
  }

  &--show-boxes#{&}--is-zone-entry {
    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      top: 160px;
    }

    #{$self}__total-box {
      top: 200px;
    }
  }

  &__total-box {
    @include playgroundBoxesSharedStyles();
    max-width: 314px;
    top: 380px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  &__left-box {
    @include playgroundBoxesSharedStyles();
    min-width: 108px;
    top: 128px;
    left: 42px;
  }

  &__center-box {
    @include playgroundBoxesSharedStyles();
    min-width: 108px;
    top: 128px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__right-box {
    @include playgroundBoxesSharedStyles();
    min-width: 108px;
    top: 128px;
    right: 42px;
  }

  &__arrow-left {
    position: absolute;
    bottom: 228px;
    left: 188px;
  }

  &__arrow-right {
    position: absolute;
    bottom: 228px;
    right: 188px;
  }

  &--is-zone-entry {
    #{$self}__total-box {
      top: 250px;
    }

    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      top: unset;
      bottom: 148px;
    }

    #{$self}__arrow-left {
      bottom: -14px;
    }
    #{$self}__arrow-right {
      bottom: -14px;
    }
  }

  &--box-count_2#{&}--is-zone-entry {
    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      bottom: 119px;
    }
  }

  &--box-count_3#{&}--is-zone-entry {
    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      bottom: 89px;
    }
  }

  &--box-count_4#{&}--is-zone-entry {
    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      bottom: 59px;
    }
  }

  &--box-count_5#{&}--is-zone-entry {
    #{$self}__left-box,
    #{$self}__center-box,
    #{$self}__right-box {
      bottom: 29px;
    }
  }
}
