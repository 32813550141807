.video-row-actions {
  display: flex;
  height: 16px;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;

  &__cropped-time {
    padding-right: 0.4rem;
  }

  &__action {
    cursor: pointer;
  }

  &__data-list {
    height: 16px;
    width: 16px;
  }
}
