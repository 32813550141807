@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.goalkeepers-shootouts-content {
  @include shotsAndPassesContentsStyles();

  &__main {
    @include shotsAndPassesMainClassStyles();
    width: 100%;
    justify-content: space-between;
    align-self: center;
    box-sizing: border-box;

    &__playground-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      &__user-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.4rem;
        font-size: 1.3rem;
        font-weight: 700;
        text-align: center;
      }

      &__user-box-total {
        margin-bottom: 0.8rem;
      }
    }

    &__form-box__save-button button {
      width: 100%;
    }
  }
}
