@import '../../../styles/functions';
@import '../../../styles/mixins';

.tcms-button {
  @include tcmsButton();
  border: 1px solid colorTcms(slate, 300);
  background-color: colorTcms(white);
  $self: &;

  @include sizes();

  &--has-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;

    path {
      transition: 0.3s;
    }
  }

  &--text-align_start {
    padding: 0.5rem 1.2rem;
    justify-content: flex-start;
  }

  &--text-align_end {
    padding: 0.5rem 1.2rem;
    justify-content: flex-end;
  }

  &--variant_filled {
    &:hover {
      background-color: colorTcms(slate, 100);
    }
  }

  &--variant_outlined {
    border-color: colorTcms(slate, 300);
    color: colorTcms(slate, 950);

    &:hover {
      background-color: colorTcms(slate, 100);
    }
  }

  &--variant_text {
    border: none;
    height: 24px;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  &--color_blue {
    &#{$self}--variant_filled {
      background-color: colorTcms(blue, 700);
      border-color: colorTcms(blue, 700);
      color: colorTcms(white);

      &:hover {
        background-color: colorTcms(blue, 800);
      }
    }

    &#{$self}--variant_outlined {
      border-color: colorTcms(blue, 700);
      color: colorTcms(blue, 700);

      &:hover {
        background-color: colorTcms(blue, 100);
      }
    }

    &#{$self}--variant_text {
      border: none;
      height: 24px;
      background-color: transparent;
      color: colorTcms(blue, 700);
    }
  }

  &--color_gray {
    &#{$self}--variant_filled {
      background-color: colorTcms(slate, 100);
      border-color: colorTcms(slate, 100);

      &:hover {
        background-color: colorTcms(slate, 200);
      }
    }

    &#{$self}--variant_outlined {
      border-color: colorTcms(slate, 100);

      &:hover {
        background-color: colorTcms(slate, 100);
      }
    }

    &#{$self}--variant_text {
      border: none;
      height: 24px;
      background-color: transparent;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
