@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.faceoffs-content {
  @include shotsAndPassesContentsStyles();

  &__main {
    @include shotsAndPassesMainClassStyles();
    padding-bottom: 2rem;
    box-sizing: border-box;

    &__playground-wrapper {
      width: 1024px;
      height: 512px;
      min-width: 1024px;
    }

    &__form-box__save-button button {
      margin-top: 0.8rem;
      width: 100%;
    }
  }
}
