@import '../../../../styles/mixins';

.video-shifts-select-list {
  @include videoSelectListSharedStyles();

  &__toolbar-header {
    margin-bottom: 0.8rem;
    font-weight: bold;
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;

    &__shift-events-filter {
      display: flex;
      gap: 1rem;

      > button {
        width: 88px;
      }
    }

    &__all-button {
      p {
        margin: 0;
        font-weight: bold;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-size: 1.4rem;
      }
    }
  }
}
