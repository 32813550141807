@import '../../../styles/functions';
@import '../../../styles/mixins';

.filter-wrapper {
  display: flex;
  flex-direction: column;
  background-color: color(cool-gray, 10);
  padding-top: 2.6rem;

  &__container {
    flex-direction: column;
    @include pageContainer();

    &__filter-box {
      display: flex;
      justify-content: center;

      &--is-extended {
        justify-content: space-between;
      }

      &__filter-parts {
        display: flex;
        flex-direction: column;

        &__base {
          display: flex;
          flex-wrap: wrap;
          gap: 0.8rem;
          padding-bottom: 1.5rem;

          > div {
            width: 180px;
          }
        }
      }
    }

    &__additional-filter-element {
      padding: 2rem 0 1.5rem;

      &--is-extended {
        padding-bottom: 0;
      }
    }

    &__tabs-list {
      display: flex;
      justify-content: center;
      padding-top: 2rem;
    }
  }
}
