@import '../../../styles/functions';

.player-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: color(white);
  padding: 3.2rem 0;
  gap: 1.5rem;

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .player-info-item {
      border-right: 1px solid color(gray, 40);

      &:last-child {
        border-right: none;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 1.2rem;
  }
}
