@import '../../../styles/functions';

.player-circle {
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  border: 1px solid color(white);
  border-radius: 100%;
  box-shadow: 0 2px 8px 0 rgba(color(cool-gray, 80), 0.15);
  background: color(purple, 80);
  transition: all 0.2s;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 2px 16px 0 rgba(color(cool-gray, 80), 0.75);
    z-index: 1000;
  }

  &--team_away {
    background: color(orange, 60);
  }

  &__name {
    position: relative;
    top: 50%;
    color: color(cool-gray, 80);
    text-align: left;
    font-size: 1.2rem;
    font-weight: bold;
    text-shadow: 0.5px 0.5px color(white);
    line-height: 1.24;
    white-space: nowrap;
    pointer-events: none;
    transform: translate(0.2rem, -50%);
  }
}
