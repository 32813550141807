.head-to-head-graph {
  display: flex;
  flex-direction: column;
  animation: fade-in 300ms ease-in-out forwards;

  &__row {
    display: flex;
    flex-direction: row;
  }
}
