@import '../../../styles/functions';

.metric-selection-list {
  display: flex;
  flex-direction: column;
  width: 232px;
  background-color: color(black);

  &__delete-box {
    font-size: 1.3rem;
    font-weight: bold;
    color: color(white);
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.8rem;
  }

  &__empty-button {
    height: 24px;
    border-radius: 4px;
    margin-bottom: 0.2rem;
    background-color: color(gray, 80);
  }
}
