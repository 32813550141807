$toggle-select-gap: 3.2rem;
$toggle-select-small-gap: 0.2rem;
$toggle-select-two-columns-gap: 0.8rem;

.toggle-select {
  display: flex;
  flex-wrap: wrap;
  column-gap: $toggle-select-gap;
  row-gap: $toggle-select-small-gap;
  $self: &;

  &--two-columns {
    gap: $toggle-select-two-columns-gap;

    * {
      flex-basis: calc(50% - ($toggle-select-two-columns-gap / 2));
    }

    *:first-child:nth-last-child(odd) {
      flex-basis: 100%;
    }
  }

  &--has-small-gap {
    gap: $toggle-select-small-gap;

    &#{$self}--two-columns {
      * {
        flex-basis: calc(50% - ($toggle-select-small-gap / 2));
      }
    }

    &#{$self}--has-only-one-column {
      gap: $toggle-select-small-gap;
    }
  }

  &--has-only-one-column {
    gap: $toggle-select-two-columns-gap;
    flex-direction: column;
  }
}
