@import '../../styles/functions';

.tooltip {
  display: none;
  position: absolute;
  width: auto;
  min-height: 1.2rem;
  padding: 0.4rem;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: calc(100% + 8px);
  border-radius: 8px;
  background-color: color(cool-gray, 100);
  color: color(white);
  font-size: 1.2rem;
  z-index: 10;
  $self: &;

  &--effect_fade {
    opacity: 0;

    &#{$self}--is-shown {
      animation: fade-in 0.3s ease-in-out forwards;
    }
  }

  &--reversed {
    position: absolute;
    left: unset;
    right: 0;
  }

  &::after {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    bottom: -8px;
    left: 50%;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent color(cool-gray, 100);
    transform: rotate(180deg) translateX(calc(-50% + 16px));
    content: '';
  }

  &::before {
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    bottom: -8px;
    left: 0;
    content: '';
  }

  &--variant_white {
    box-shadow:
      0 1px 2px 0 rgba(18, 22, 25, 0.1),
      0 2px 12px 0 rgba(18, 22, 25, 0.25);
    background-color: color(white);
    color: color(cool-gray, 100);

    &::after {
      border-color: transparent transparent color(white);
    }
  }

  &--arrow-direction {
    &_up {
      top: calc(100% + 8px);
      bottom: unset;

      &::after {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: rotate(0deg) translateX(-50%);
        content: '';
      }

      &::before {
        top: -8px;
        left: 0;
      }
    }

    &_left {
      min-height: 2rem;
      top: 50%;
      left: unset;
      right: -8px;
      transform: translateX(100%);

      &::after {
        position: absolute;
        top: calc(50% - 4px);
        left: -10px;
        transform: rotate(-90deg);
        content: '';
      }

      &::before {
        width: 8px;
        height: 100%;
        top: 0;
        left: -8px;
      }
    }

    &_right {
      min-height: 2rem;
      top: 50%;
      left: -8px;
      transform: translateX(-100%);

      &::after {
        position: absolute;
        top: calc(50% - 4px);
        right: -10px;
        left: unset;
        transform: rotate(90deg);
        content: '';
      }

      &::before {
        width: 8px;
        height: 100%;
        top: 0;
        left: unset;
        right: -8px;
      }
    }
  }

  &--center-xaxis {
    left: 50%;
    transform: translateX(-50%);
  }

  &--center-yaxis {
    top: 50%;
    transform: translate(100%, -50%);
  }

  &--is-shown {
    display: flex;
  }

  &--arrow-aligment_up::after {
    top: unset;
    bottom: 16px;
  }

  &--arrow-aligment_down::after {
    top: 16px;
    bottom: unset;
  }

  &--arrow-aligment_left::after {
    left: 16px;
    right: unset;
  }

  &--arrow-aligment_right::after {
    left: unset;
    right: 16px;
  }
}
