@import '../../../styles/functions';
@import '../../../styles/mixins';

.playground-bar {
  position: absolute;
  width: 100%;
  height: 45px;
  border-radius: 0 0 16px 16px;
  background-color: color(cool-gray, 100);
  bottom: -43px;
  z-index: 10;
  $self: &;

  &--is-reversed {
    top: 0;
    bottom: unset;
    border-radius: 16px 16px 0 0;

    & #{$self}__icons {
      top: 0;
      bottom: unset;
    }

    &#{$self}--show-boxes {
      border-radius: 16px 16px 0 0;
    }
  }

  &--show-boxes {
    height: auto;
    border-radius: 0 0 16px 16px;

    & #{$self}__box-list {
      display: flex;
    }
  }

  &__box-list {
    display: none;
  }

  &__stats {
    display: flex;
    width: 100%;
    height: 45px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      width: 100px;
      color: color(white);
      font-size: 1.4rem;
      text-align: center;
      font-weight: bold;
      line-height: 1.57;

      &:not(:last-child) {
        border-right: 1px solid color(white);
      }
    }
  }

  &__icons {
    position: absolute;
    display: flex;
    height: 45px;
    flex-direction: row;
    align-items: center;
    bottom: 0;
    right: 3.2rem;
    gap: 1.6rem;
  }

  @include media-query(xl) {
    &__stats {
      span {
        width: 80px;
        font-size: 1.2rem;
      }
    }

    &__icons {
      height: 45px;
      align-items: center;
      bottom: 0;
      right: 1.6rem;
      gap: 1.6rem;
    }
  }
}
