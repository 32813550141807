@import '../../../styles/functions';

.table-head {
  background-image: linear-gradient(to bottom, color(cool-gray, 10), color(cool-gray, 20) 100%);
  text-transform: uppercase;

  tr {
    height: 3.8rem;

    > th {
      text-align: center;
      padding: 0 0.8rem;
    }
  }

  &--table-part_right {
    text-transform: none;
  }

  &--table-part_center {
    tr > th {
      padding: 0;
      text-transform: none;
    }
  }

  &--variant_tcms {
    background: transparent;
    text-transform: none;
    border-bottom: 1px solid colorTcms(slate, 300);
  }
}
