@import '../../../styles/functions';

.card-item-list {
  display: flex;
  gap: 3.2rem;
  padding: 3.2rem 0;
  max-width: 150rem;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-start;

  &--is-at-compare {
    gap: 0.8rem;
    padding-top: 1.6rem;
    padding-bottom: 0;
    margin: 0;

    > div {
      width: 100%;
    }
  }

  &--is-exporting {
    .card {
      box-shadow: unset;
      border: 1px solid color(gray, 30);
    }
  }

  &--mode_wrap {
    flex-direction: unset;
    flex-wrap: wrap;
    justify-content: center;
  }
}
