@import '../../../styles/mixins';
@import '../../../styles/functions';

.playground-bar-box-list {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 1.1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: color(cool-gray, 100);
  $self: &;

  &--is-reversed {
    border-radius: 16px 16px 0 0;
  }

  &__heading {
    margin-bottom: 8px;
    color: color(white);
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
    line-height: 1.57;
  }

  &__boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }

  &--is-small &__boxes {
    gap: 0.1rem;
    padding: 0px 1.3rem;
  }

  &--is-responsive {
    @include media-query(xxl) {
      #{$self}__boxes {
        gap: 0;
      }
    }
  }
}
