@import '../../../styles/functions.scss';

.wysiwyg {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid colorTcms(slate, 300);
  background-color: colorTcms(white);

  &__toolbar {
    display: flex;
    padding: 0.8rem 1.2rem;
    align-items: center;
    border-bottom: 1px solid colorTcms(slate, 300);
    gap: 0.8rem;

    button {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: flex-start;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover path#Rectangle {
        fill: colorTcms(slate, 100);
      }
    }
  }

  div[role='textbox'] {
    padding: 0.8rem 1.2rem;
    outline: none;

    span[data-slate-placeholder='true'] {
      top: 1.5em !important;
    }
  }
}
