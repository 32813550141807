@import '../../styles/functions';

.display-field {
  height: 38px;
  box-sizing: border-box;
  background-color: color(cool-gray, 10);
  border-radius: 0.8rem;
  padding: 0.7rem 0.8rem;

  &__inner {
    font-size: 1.4rem;
    height: 24px;
    background-color: color(white);
    box-sizing: border-box;
    padding: 0.3rem 0.6rem;
    border-radius: 0.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
