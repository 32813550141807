@import '../../../../styles/functions';

.goalkeeper-card-content-component {
  flex-grow: 1;
  background-color: color(cool-gray, 10);

  &--is-loading {
    background-color: color(white);
  }

  &__statistics {
    display: flex;
    max-width: 746px;
    gap: 3.2rem;
    margin: 0 auto;

    &__goalkeeper-goal-nets {
      display: flex;
      box-sizing: border-box;
      width: 350px;
      flex-direction: column;
      padding: 3.2rem 0;
      gap: 3rem;

      h2 {
        margin-top: 0;
        margin-bottom: 1.2rem;
        font-size: 2rem;
      }
    }
  }
}
