.table-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 16px;
  gap: 14px;

  &__rows {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__header {
    margin-top: 10px;
    display: flex;
    gap: 16px;
  }

  &__pagination {
    margin-top: 20px;
    display: flex;
    width: 408px;
    align-self: center;
    gap: 174px;
  }
}
