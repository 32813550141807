@import '../../../styles/palette';

.game-expected-goals-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.6rem;

  &__name {
    white-space: nowrap;
    font-size: 1.2rem;
    text-align: right;
    margin: 0;

    span {
      font-weight: bold;
    }

    &--reversed {
      text-align: left;
    }
  }

  &__value {
    font-size: 1.2rem;
    font-weight: bold;
    width: 3rem;
    margin: 0;
  }

  .bar {
    width: 12.8rem;
  }

  &--reversed {
    flex-flow: row-reverse nowrap;
  }
}
