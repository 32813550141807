@import '../../styles/functions';

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 35px 0;
  color: colorTcms(slate, 500);
  font-size: 1.4rem;
  text-align: center;
  line-height: 2.2rem;

  p {
    margin: 0;

    span {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
