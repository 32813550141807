.card-item {
  margin-bottom: 0.8rem;
  font-size: 1.3rem;

  &__percent-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    > div:first-child {
      width: 100%;
      height: 12px;
      margin-top: 6px;
      margin-bottom: 3px;
      position: relative;
      border-radius: 4px;
    }

    > div:last-child {
      width: 38px;
      text-align: right;
      font-weight: bold;
    }
  }
}
