@import '../../../styles/functions';

.download-management-button {
  border-radius: 2px;
  background-color: color(gray, 10);
  font-size: 1.2rem;
  color: color(white);
  padding: 0.4rem 0.6rem;
  width: fit-content;
  cursor: pointer;

  &--type_download {
    background-color: color(blue);

    &:hover,
    &:focus {
      background-color: darken(color(blue), 6);
    }
  }

  &--type_delete {
    background-color: color(orange, 40);

    &:hover,
    &:focus {
      background-color: darken(color(orange, 40), 6);
    }
  }

  &--multiple {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.8rem;
    font-weight: bold;
  }
}
