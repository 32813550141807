@import '../../../../styles/mixins';

.videomaps-heatmap-content {
  @include shotsAndPassesContentsStyles();

  &__main {
    @include shotsAndPassesMainClassStyles();

    &__color-heatmap-bar {
      visibility: visible;

      &--hidden {
        visibility: hidden;
      }
    }
  }
}
