@import '../../../styles/mixins';

.metrics-section {
  display: flex;
  flex-direction: column;

  @include dataSettingsHeaderStyles();

  &__column-names-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3.2rem;
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 0.4rem;
  }
}
