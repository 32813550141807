@import '../../../../styles/functions';
@import '../../../../styles/mixins';
@import '../../../../styles/colorVariables';

.playground-assists-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include disableUserSelect();

  &__assist {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: color(white);
    cursor: pointer;

    &:focus,
    &:active {
      z-index: 1;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: color(gray, 80);
      }
    }

    @include getColorClassesFromMap($playerColorMap) using ($color) {
      svg path {
        fill: $color;
      }
    }
  }
}
