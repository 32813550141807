@import '../../../styles/functions';

.modal-button {
  width: 100%;
  height: 48px;
  border: unset;
  background-color: transparent;
  color: colorTcms(blue, 700);
  border-top: 1px solid colorTcms(slate, 300);
  cursor: pointer;

  &--disabled {
    color: colorTcms(slate, 500);
    cursor: not-allowed;
    pointer-events: none;
  }
}
