@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.defined-templates-data-types {
  display: flex;
  flex-direction: column;
  width: 296px;

  h3 {
    color: colorTcms(blue, 700);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
  }

  &__edit-type-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 8px;

    .tcms-button {
      width: 100%;
    }
  }
}

.defined-templates-dropdown-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
  background-color: white;

  &__body {
    display: flex;
    gap: 1.6rem;

    &__item {
      width: 100%;
    }
  }
}
