@import '../../../styles/mixins';

.new-dataset {
  @include editDatasetComponentStyles();

  &__content {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
  }
}
