@import '../../../styles/variables';

.game-result {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 8rem;

  &__team {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.6rem;
    // To bylo nutne, nize popsano (pisu kvuli merge)
    width: 30%;

    h3 {
      font-size: 2rem;
    }

    // Branche feature/game-overview-data -> Nebylo to spravne uprostred.
    // Styly jsem vzal z ofiko stranky (width: 30%) a nyni je to spravne uprostred.
    &--home {
      justify-content: flex-end;
    }
  }
}
