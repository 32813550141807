@import '../../../styles/mixins';
@import '../../../styles/functions';

.modal {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(color(cool-gray, 100), 0.5);
  color: color(cool-gray, 100);
  z-index: 1000000;

  &__body {
    position: relative;
    padding: 40px 80px;
    background-color: color(white);
    box-shadow: 0 0 32px 0 color(cool-gray, 100);
    overflow: auto;
    max-height: calc(100% - 80px);

    &--overflow-visible {
      overflow: visible;
    }

    &--size {
      &_small {
        padding: 24px;
        border-radius: 16px;

        @include media-query(m) {
          padding: 16px;
        }
      }

      &_medium {
        padding: 32px;
        border-radius: 24px;
      }

      &_large {
        padding: 40px 80px;
        border-radius: 32px;

        @include media-query(l) {
          padding: 40px 60px;
        }

        @include media-query(m) {
          padding: 40px 40px;
        }
      }
    }

    &--has-modal-buttons#{&}--size {
      &_small {
        padding-bottom: 68px;
      }

      &_medium {
        padding-bottom: 80px;
      }

      &_large {
        padding-bottom: 84px;
      }
    }

    &__buttons {
      display: flex;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;

      .modal-button {
        border-right: colorTcms(slate, 300) 1px solid;

        &:last-child {
          border-right: unset;
        }
      }
    }
  }
}
