@import '../../styles/mixins';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.stick-grip-circle {
  @include letterCircleBaseStyles();

  @include getColorClassesFromMap($playerWithOnOffColorMap) using ($color) {
    border-color: $color;
    color: $color;
  }

  &--has-white-background {
    background-color: color(white);
  }
}
