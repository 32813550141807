@import '../../styles/functions';
@import '../../styles/mixins';

.button {
  display: flex;
  padding: 0.6rem 1.2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid color(blue);
  border-radius: 0.8rem;
  font-weight: bold;
  letter-spacing: normal;
  white-space: nowrap;
  line-height: 1.57;
  cursor: pointer;
  transition: 0.3s;

  &--primary {
    height: 3.8rem;
    border: none;
    background: linear-gradient(to bottom, color(blue, 50), color(blue, 60));
    color: color(white);
  }

  &--secondary {
    background-color: transparent;
    color: color(blue);

    &:hover,
    &:focus,
    &:active {
      background-color: color(blue);
      color: color(white);
      path {
        fill: color(white);
      }
    }
  }

  @include sizes();

  &--has-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;

    path {
      transition: 0.3s;
    }
  }

  &--variant_gray#{&}--primary {
    height: 3.8rem;
    border: 1px solid color(gray, 40);
    background: linear-gradient(to bottom, color(white), color(cool-gray, 10));
    color: color(cool-gray, 100);
    font-size: 1.4rem;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }

  &--variant_gray#{&}--secondary {
    color: color(cool-gray, 50);
    border: 2px solid color(cool-gray, 60);

    &:hover {
      background-color: rgba(color(cool-gray, 50), 0.1);
      text-decoration: underline;
    }

    &:focus,
    &:active {
      background-color: transparent;
    }
  }

  &--variant_normal-underlined#{&}--primary {
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }

  &--variant_normal-underlined#{&}--secondary {
    background-color: transparent;
    color: color(blue);

    &:hover {
      background-color: rgba(color(blue), 0.1);
      text-decoration: underline;
    }
  }

  &--variant_red#{&}--primary {
    height: 3.8rem;
    background: linear-gradient(to bottom, color(red, 60), color(red, 70));
    color: color(white);
    font-size: 1.4rem;
  }

  &--variant_red#{&}--secondary {
    background-color: transparent;
    color: color(red, 60);
    border: 2px solid color(red, 60);

    &:hover,
    &:focus,
    &:active {
      background-color: color(red, 60);
      color: color(white);
      path {
        fill: color(white);
      }
    }
  }

  &--is-border-radius-small {
    border-radius: 0.4rem;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
