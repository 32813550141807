@import '../../styles/mixins';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.select-option-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  background-color: color(white);

  &:hover {
    background-color: color(blue);
    color: color(white);
  }

  &--is-select-all {
    border-bottom: 1px solid color(gray, 30);
  }

  &__checkbox {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 1rem;
    cursor: pointer;
    accent-color: color(blue);

    &--variant_tcms {
      accent-color: colorTcms(blue, 700);
    }

    &--variant_login {
      accent-color: colorTcms(sky, 200);
    }
  }

  @include getColorClassesFromMap($playerWithOnOffColorMap) using ($color) {
    background-color: rgba($color, 0.2);
    color: $color;
  }

  &--variant_tcms {
    height: 40px;
    background-color: colorTcms(white);

    &:hover {
      background-color: colorTcms(blue, 700);
      color: colorTcms(white);
    }
  }

  &--variant_login {
    height: 40px;
    background-color: colorTcms(white);

    &:hover {
      background-color: colorTcms(sky, 200);
      color: inherit;
    }
  }

  &__icon {
    margin-right: 1.2rem;
  }
}
