.goal-net-shootout-content {
  width: 100%;
  height: 100%;

  &__box-wrapper {
    &-0 {
      display: flex;
      position: absolute;
      width: 280px;
      height: 260px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
    }

    &-1 {
      display: flex;
      position: absolute;
      width: 200px;
      height: 240px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 50%;
      top: 48px;
      transform: translateX(-50%);
    }

    &-2 {
      display: flex;
      position: absolute;
      width: 280px;
      height: 260px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
    }

    &-3 {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 280px;
      height: 260px;
      left: 0;
      bottom: 0;
    }

    &-4 {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 160px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
    }

    &-5 {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 280px;
      height: 260px;
      right: 0;
      bottom: 0;
    }
  }
}
