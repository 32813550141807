@import '../../../styles/functions';
@import '../../../styles/mixins';

.log-stacked-bar-chart {
  @include disableUserSelect();
  position: relative;
  background-color: colorTcms(white);

  &__arrow {
    position: absolute;
    z-index: 5;
    right: 16px;
    bottom: 133px;
    cursor: pointer;

    line {
      stroke: colorTcms(black);
    }

    &--left {
      left: 16px;
      transform: rotateY(180deg);
    }

    &--disabled {
      pointer-events: none;

      line {
        stroke: colorTcms(slate, 500);
      }
    }
  }
}

.log-stacked-bar-chart__bar-tick {
  font-size: 12px;

  @include media-query(xl) {
    font-size: 10px;
  }
}
