@import '../../styles/palette';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.circle-bar {
  width: 138px;
  height: 138px;
  background-color: color(cool-gray, 10);
  border-radius: 50%;
  display: grid;
  place-items: center;

  &__inner-circle {
    display: grid;
    place-items: center;
    border-radius: 50%;
    color: color(white);
    font-size: 2rem;
    font-weight: bold;
    margin: 0;

    &--team_home {
      background-image: $bar-home;
    }
    &--team_away {
      background-image: $bar-away;
    }
    &--small {
      font-size: 1.6rem;
    }
  }
}
