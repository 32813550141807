@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/functions';

.date-range-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__arrow-pointer {
    position: absolute;
    box-sizing: border-box;
    background-color: color(white);
    height: 15px;
    width: 15px;
    transform: rotate(45deg);
    border-left: 1px solid color(gray, 40);
    border-top: 1px solid color(gray, 40);
    z-index: 101;
    top: $input-height;
    margin-top: 3px;
  }

  &__pop-up {
    position: absolute;
    box-sizing: border-box;
    background-color: color(white);
    z-index: 100;
    top: $input-height;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid;
    @include filterStyle();

    &--disable-arrow-pointer {
      margin-top: 0;
    }

    &__pickers-box {
      display: flex;
      @include media-query(m) {
        flex-direction: column;
      }

      &--selected {
        background-color: color(blue);

        &:hover {
          background-color: color(blue);
        }
      }
    }

    &__controls {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      padding: 16px;
      padding-top: 0;

      &--has-extra-element {
        justify-content: space-between;
      }

      &__buttons {
        display: flex;
        gap: 20px;

        button {
          width: 100px;
        }
      }
    }
  }
}
