@import '../../../../styles/mixins';

.user-management-page {
  flex-direction: column;
  @include tcmsPageContainer();
  @include tcmsSharedToolbar();

  &__content {
    @include card();

    hr {
      background-color: colorTcms(slate, 300);
    }
  }
}
