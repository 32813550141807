@import '../../../styles/mixins';

.summary-percentile-column-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 100%;

  &--is-shown::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: color(cool-gray, 70) transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    margin-left: auto;
    margin-right: auto;
    z-index: 11;
  }

  &__label {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.summary-percentile-column-cell {
  width: 11rem;
  font-weight: bold;

  @include percentileBoxStyles();
  @include cellColors();

  &--font-weight-inherit {
    font-weight: inherit;
  }
}
