@import '../../../styles/functions';

.metric-tooltip {
  display: none;
  position: absolute;
  top: -50%;
  left: calc(100% + 8px);
  z-index: 1000;
  background: color(white);
  color: color(black);
  border: 1px solid color(black);
  border-radius: 3px;
  padding: 0.6rem 1rem;
  width: 200px;
  margin-top: 1.3rem;
  font-weight: 400;
  pointer-events: none;

  &::after {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 7px;
    left: -12px;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent color(black);
    transform: rotate(270deg);
    content: '';
  }

  &--is-shown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fade-in 0.2s ease-in-out;

    h4 {
      margin: 0;
      margin-bottom: 0.6rem;
      color: color(blue);
      font-size: 1.1rem;
      text-align: center;
      font-weight: bold;
      line-height: 1.09;
      text-transform: uppercase;
    }
  }
}
