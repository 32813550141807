@import '../../../../styles/mixins';

.game-report-table-component {
  @include disableUserSelect();

  table {
    border-spacing: 0;
    font-size: 1.2rem;

    thead {
      th {
        padding: 0;
      }
    }

    tbody {
      td {
        padding: 0;
      }
    }
  }

  &__header {
    background-image: linear-gradient(to bottom, color(cool-gray, 10), color(cool-gray, 20) 100%);
    text-transform: uppercase;
  }

  &__row {
    background-color: color(white);

    &--expanded {
      background-color: color(cool-gray, 10);
    }
  }
}
