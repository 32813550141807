@import '../../styles/functions';
@import '../../styles/mixins';

.color-heatmap-bar {
  width: 1.8rem;
  border-radius: 1rem;
  border: 1px solid color(gray, 30);
  padding: 0.4rem;

  &__bar {
    height: 5rem;

    &:first-of-type {
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    }

    &--color_white {
      background-color: color(white);
      height: 9rem;
    }

    @include heatmapColors();
  }
}
