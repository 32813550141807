.video-event-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 38px;
  font-size: 1.4rem;
  padding: 0.9rem 1.1rem;

  p {
    font-size: 1.4rem;
    margin: 0;
  }

  &__info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.6rem;
  }

  &__team-and-game-state {
    width: 2.8rem;
  }

  &__time {
    padding-left: 1rem;
    width: 4rem;
  }

  &__shift-event {
    width: 12rem;
  }
}
