@import '../../../styles/functions';
@import '../../../styles/mixins';

.tcms-list {
  display: flex;
  width: 488px;
  flex-direction: column;
  gap: 0.2rem;
  @include disableUserSelect();

  &--size_medium {
    width: 392px;
  }

  &--size_small {
    width: 296px;
  }
}
