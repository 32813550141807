@import '../../../styles/functions';

.metrics-section-row {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  &:not(:last-child) {
    padding-bottom: 1.6rem;
  }

  &__header {
    font-size: 1.2rem;
    font-weight: bold;
    color: color(cool-gray, 50);
  }

  &__metrics {
    display: flex;
    flex-direction: column;
  }
}
