.filter-extended-part {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding-bottom: 1.5rem;

  > div {
    width: 180px;
  }
}
