@import './palette';
@import './variables';
@import './functions';

@mixin getColorClassesFromMap($map, $self: '') {
  @each $modifier, $color in $map {
    &#{$self}--#{$modifier} {
      @content ($color);
    }
  }
}

@mixin getCardAttribute($wantBackground, $color) {
  @if $wantBackground {
    background-color: $color;
  } @else {
    fill: $color;
  }
}

@mixin cardColors($wantBackground: true) {
  &--color_color0 {
    @include getCardAttribute($wantBackground, color(cool-gray, 10));
  }

  &--color_color1 {
    @include getCardAttribute($wantBackground, color(red, 60));
  }

  &--color_color2 {
    @include getCardAttribute($wantBackground, color(red, 40));
  }

  &--color_color3 {
    @include getCardAttribute($wantBackground, color(red, 30));
  }

  &--color_color4 {
    @include getCardAttribute($wantBackground, color(red, 20));
  }

  &--color_color5 {
    @include getCardAttribute($wantBackground, color(green, 20));
  }

  &--color_color6 {
    @include getCardAttribute($wantBackground, color(green, 30));
  }

  &--color_color7 {
    @include getCardAttribute($wantBackground, color(green, 40));
  }

  &--color_color8 {
    @include getCardAttribute($wantBackground, color(green, 50));
  }
}

@mixin cardColorsContrastText() {
  &--color_color1,
  &--color_color2,
  &--color_color6,
  &--color_color7,
  &--color_color8 {
    color: color(white);
  }
}

@mixin cellColors($wantBackground: true) {
  &--color_cell0 {
    @include getCardAttribute($wantBackground, color(red, 50));
  }

  &--color_cell1 {
    @include getCardAttribute($wantBackground, color(red, 40));
  }

  &--color_cell2 {
    @include getCardAttribute($wantBackground, color(red, 30));
  }

  &--color_cell3 {
    @include getCardAttribute($wantBackground, color(red, 20));
  }

  &--color_cell4 {
    @include getCardAttribute($wantBackground, color(red, 10));
  }

  &--color_cell5 {
    @include getCardAttribute($wantBackground, color(green, 10));
  }

  &--color_cell6 {
    @include getCardAttribute($wantBackground, color(green, 20));
  }

  &--color_cell7 {
    @include getCardAttribute($wantBackground, color(green, 30));
  }

  &--color_cell8 {
    @include getCardAttribute($wantBackground, color(green, 40));
  }

  &--color_cell9 {
    @include getCardAttribute($wantBackground, color(green, 50));
  }
}

@mixin heatmapColors() {
  &--color_red1 {
    background-color: color(red, 80);
  }

  &--color_red2 {
    background-color: color(red, 60);
  }

  &--color_red3 {
    background-color: color(red, 40);
  }

  &--color_red4 {
    background-color: color(red, 30);
  }

  &--color_red5 {
    background-color: color(red, 20);
  }

  &--color_blue1 {
    background-color: color(blue, 80);
  }

  &--color_blue2 {
    background-color: color(blue, 60);
  }

  &--color_blue3 {
    background-color: color(blue, 40);
  }

  &--color_blue4 {
    background-color: color(blue, 30);
  }

  &--color_blue5 {
    background-color: color(blue, 20);
  }
}
