@import '../../../../../../styles/mixins';

.teams-table-box {
  position: relative;
  height: 300px;
  width: 1554px;

  @include media-query(xxl) {
    width: 1338px;
  }

  @include media-query(xl) {
    width: 1187px;
  }
}
