@import '../../styles/functions';

.pagination-controls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  padding: 16px;
  width: 408px;

  span {
    font-size: 1.4rem;
    font-weight: normal;
    color: color(cool-gray, 60);
  }
}
