.game-button-list {
  position: relative;
  display: flex;
  gap: 2.6rem;
  justify-content: space-between;
  align-items: center;

  &__scroll-part {
    display: flex;
    gap: 1.6rem;
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .loading-inner--is-mini {
    top: 0;
  }
}
