@import '../../../styles/mixins';
@import '../../../styles/functions';

.playground-icon-with-tooltip {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  @include disableUserSelect();

  &:hover &__tooltip {
    display: flex;
  }

  &__tooltip {
    display: none;
    position: absolute;
    width: 200px;
    height: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: -42px;
    left: 4px;
    border-radius: 10px;
    box-shadow:
      0 1px 2px 0 rgba(color(cool-gray, 100), 0.4),
      0 2px 12px 0 rgba(color(cool-gray, 100), 0.6);
    background-color: color(white);
    font-size: 1.3rem;
    transform: translateX(-50%);
    gap: 8px;

    &:after {
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      bottom: -8px;
      left: calc(50% - 5px);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid color(white);
      content: '';
    }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;

    &--rotate-icon {
      transform: rotate(180deg);
    }

    &--is-clickable {
      cursor: pointer;
    }
  }
}
