@import '../../../../styles/mixins';

.report-content-columns {
  &__expand-icon-cell {
    @include cellBase();
    justify-content: flex-start;
    align-items: flex-start;

    div {
      padding-top: 11.5px;
    }

    &--expanded {
      height: 280px;
    }
  }

  &__name-cell {
    @include cellBase();
    width: 128px;
    padding-top: 12px;
    align-items: flex-start;

    @include media-query(xxl) {
      width: 100px;
    }

    @include media-query(xl) {
      width: 90px;
    }

    &--expanded {
      height: 280px;
    }
  }

  &__description-cell {
    @include cellBase();

    width: 400px;
    justify-content: flex-end;
    padding-top: 12px;
    align-items: flex-start;
    font-weight: bold;
    text-align: end;

    @include media-query(xxl) {
      width: 340px;
    }

    @include media-query(xl) {
      width: 255px;
    }

    &--expanded {
      height: 280px;
    }
  }
}
