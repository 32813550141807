@import '../../../../../styles/mixins';

.screen-chart-box {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &--error {
    color: red;
    text-align: center;
  }

  &__chart {
    @include tcmsTrackingCardContainerStyle();
    height: 100%;
    padding: 0 15px;
  }

  &__no-data {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
  }
}
