@import '../../../styles/functions';
@import '../../../styles/mixins';

.screen-bar-chart {
  @include disableUserSelect();
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: colorTcms(white);

  &__gradient-element {
    width: 56px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 171px;
    @include tcmsGrayGradient(90deg, 1);
  }

  &__chart {
    padding-left: 167px;

    svg {
      overflow: visible;
    }
  }
}

.screen-bar-chart__bar-tick {
  font-size: 1.4rem;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;

    .tcms-button {
      width: 160px;
      height: 32px;
    }

    span {
      color: colorTcms(slate, 500);
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.4rem;
    }

    &__number {
      display: flex;
      align-items: center;
      height: 100%;
      font-weight: bold;
      padding-right: 17px;
      color: colorTcms(slate, 950);
    }
  }
}
