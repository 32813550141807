@import '../../../styles/mixins';
@import '../../../styles/functions';

.metric-column-header {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 100%;
  min-width: 130px;
  height: 38px;
  padding: 0.4rem 0.5rem 0;
  border-bottom: 2px solid;

  &--metric-line_green {
    border-color: color(green, 50);
  }

  &--metric-line_red {
    border-color: color(red, 50);
  }

  &__category {
    display: flex;
    justify-content: center;
  }

  &__metric-name {
    text-wrap: nowrap;
  }

  &--is-shown::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: color(cool-gray, 70) transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    margin-left: auto;
    margin-right: auto;
    z-index: 11;
  }
}

.metric-column-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &__value {
    box-sizing: border-box;
    width: 4.6rem;
    padding: 0.5rem;
    border-radius: 0.3rem;

    &--has-link {
      cursor: pointer;
      text-decoration: underline;
      color: color(blue, 90);

      &:hover {
        color: color(blue, 60);
        text-decoration: none;
      }
    }
  }

  &__percentile {
    position: relative;
    width: 4.6rem;
    font-weight: normal;

    @include percentileBoxStyles();
    @include cellColors();
  }
}
