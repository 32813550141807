@import '../../../styles/mixins';
@import '../../../styles/variables';

.table-filter {
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
  flex-wrap: wrap;

  > div {
    width: 18.8rem;
  }

  > button {
    width: 18.8rem;
    margin-top: $caption-height;
  }

  &--break-elements {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    > div.checkbox-button {
      grid-row: 2;
    }

    @include media-query(l) {
      padding-top: 1rem;
      grid-template-rows: 1fr auto auto;
      grid-template-columns: 1fr 1fr;

      > button {
        grid-row: 1;
        margin-top: 0;
      }

      > div.checkbox-button {
        grid-row: 3;
      }

      > div {
        grid-row: 2;
      }
    }
  }
}
