@import '../../../styles/functions';

.goal-net-stats-circle {
  display: flex;
  position: relative;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 1px rgba(color(cool-gray, 100), 0.12);
  border-radius: 100%;
  background-color: color(white);
  color: color(cool-gray, 100);
  font-size: 1.4rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
