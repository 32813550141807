.player-info-item {
  display: flex;
  min-width: 69px;
  padding: 0.3rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  text-align: center;

  strong {
    font-size: 1.2rem;
  }
}
