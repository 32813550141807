@import '../../styles/variables';
@import '../../styles/functions';

.caption {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: $caption-line-height;
  margin-bottom: $caption-margin-bottom;

  &--color_black {
    color: color(cool-gray, 100);
  }

  &--color_white {
    color: color(white);
  }

  &--color_slate {
    color: colorTcms(slate, 500);
  }

  &--variant_tcms {
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 8px;
    text-transform: none;
  }
}
