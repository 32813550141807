.heatmap-comparative-component {
  position: relative;

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 200px 200px 0 0;
  }
}
