@import '../../../../styles/mixins';

.event-log-page {
  @include tcmsPageContainer();
  padding-top: 24px;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    flex-grow: 1;
    gap: 40px;
  }
}
