@import '../../../styles/functions';
@import '../../../styles/mixins';

.bar-chart-tooltip {
  @include tcmsTooltipStyle();
  min-width: 132px;

  hr {
    margin-top: 4px;
    background-color: colorTcms(slate, 300);
  }

  span {
    color: colorTcms(cyan, 500);
  }

  &--type_team {
    span {
      color: colorTcms(blue, 700);
    }
  }

  &--type_media {
    span {
      color: colorTcms(fuchsia, 600);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 1.4rem;
      line-height: 2.2rem;
      font-weight: bold;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding-top: 8px;

    span {
      font-weight: bold;
    }
  }
}
