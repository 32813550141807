@import '../../../../styles/mixins';

.face-off-playground-content {
  position: relative;
  width: 100%;
  height: 100%;

  &__left-top-face-off-box {
    @include playgroundBoxesSharedStyles();
    top: 64px;
    left: 119px;
    text-align: center;
  }

  &__left-bottom-face-off-box {
    @include playgroundBoxesSharedStyles();
    left: 119px;
    bottom: 64px;
  }

  &__left-top-line-box {
    @include playgroundBoxesSharedStyles();
    top: 64px;
    left: 360px;
    text-align: center;
  }

  &__left-bottom-line-box {
    @include playgroundBoxesSharedStyles();
    left: 360px;
    bottom: 64px;
  }

  &__center-box {
    @include playgroundBoxesSharedStyles();
    min-width: 108px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__right-top-face-off-box {
    @include playgroundBoxesSharedStyles();
    top: 64px;
    right: 119px;
    text-align: center;
  }

  &__right-bottom-face-off-box {
    @include playgroundBoxesSharedStyles();
    right: 119px;
    bottom: 64px;
  }

  &__right-top-line-box {
    @include playgroundBoxesSharedStyles();
    top: 64px;
    right: 360px;
    text-align: center;
  }

  &__right-bottom-line-box {
    @include playgroundBoxesSharedStyles();
    right: 360px;
    bottom: 64px;
  }

  &__attack-zone {
    top: 228px;
    right: 119px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.6;
    text-transform: uppercase;
    position: absolute;
  }

  &__defend-zone {
    left: 119px;
    top: 228px;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 1.6;
    position: absolute;
  }
}
