@import '../../../styles/variables';
@import '../../../styles/mixins';

.filter-buttons {
  height: 3.8rem;
  display: flex;
  gap: 0.8rem;
  padding-top: $caption-height;
  padding-bottom: 1.5rem;
  align-self: flex-start;
  padding-left: 0.8rem;

  @include media-query(m) {
    flex-direction: column;
  }

  > button {
    width: 180px;
  }

  &--open-more {
    align-self: flex-end;

    @include media-query(m) {
      align-self: flex-start;
    }

    @include media-query(xxl) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
