@import '../../../styles/mixins';

.game-report-column-header {
  position: relative;
  width: 232px;

  @include media-query(xxl) {
    width: 200px;
  }

  @include media-query(xl) {
    width: 186px;
  }

  &__labels {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    text-align: center;
    gap: 8px;

    &__label {
      width: 80px;
      text-transform: uppercase;
    }
  }

  &__logos {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 54px;
    width: calc(100% * 2);
    top: -58px;
    z-index: 2;

    @include media-query(xxl) {
      gap: 38px;
    }

    @include media-query(xl) {
      gap: 31px;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.game-report-column-cell {
  @include cellBase();
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid color(gray, 20);

  &--expanded {
    height: 280px;
  }

  &__values-box {
    display: flex;
    padding-top: 6px;
    gap: 8px;
  }

  &__value {
    width: 80px;
    height: 27px;
    line-height: 27px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: color(white);

    @include cellColors();
  }
}
