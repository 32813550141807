@import '../../styles/functions';

.navigation-bar-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.57;
  cursor: pointer;
  transition: 0.1s;

  &:hover,
  &:focus,
  &:active {
    color: color(blue);
  }
}
