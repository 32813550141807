@import '../../styles/mixins';
@import '../../styles/functions';

.container {
  display: flex;
  width: 1537.2px;
  margin: 0 auto;

  &--align-items_start {
    align-items: flex-start;
  }

  &--align-items_end {
    align-items: flex-end;
  }

  &--align-items_center {
    align-items: center;
  }

  &--align-items_baseline {
    align-items: baseline;
  }

  &--align-items_stretch {
    align-items: stretch;
  }

  &--flex-direction_column {
    flex-direction: column;
  }

  &--flex-direction_column-reverse {
    flex-direction: column-reverse;
  }

  &--flex-direction_row {
    flex-direction: row;
  }

  &--flex-direction_row-reverse {
    flex-direction: row-reverse;
  }

  &--justify-content_center {
    justify-content: center;
  }

  &--justify-content_start {
    justify-content: flex-start;
  }

  &--justify-content_end {
    justify-content: flex-end;
  }

  &--justify-content_between {
    justify-content: space-between;
  }

  &--justify-content_around {
    justify-content: space-around;
  }

  &--justify-content_evenly {
    justify-content: space-evenly;
  }

  @include media-query(xxl) {
    width: 1399.9px;
  }
}
