@import '../../styles/colorVariables';
@import '../../styles/functions';

.team-metric-legend {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  &__title {
    font-size: 1.4rem;
    margin-top: 0;
  }

  &__metric-box {
    padding: 0.5rem;
    color: color(white);
    text-align: center;
    border-radius: 4px;
    background-image: $bar-home;

    &--is-away {
      background-image: $bar-away;
    }
  }
}
