.trend-toolbar {
  display: flex;
  align-items: flex-end;
  gap: 1.6rem;

  > div {
    width: 18rem;
  }

  &__button-label {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 1.4rem;
  }
}
