@import '../../styles/functions';

.no-data-games {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 72px;
  gap: 1rem;

  &__icon {
    width: 2rem;
    height: 1.6rem;
  }

  &__message {
    font-size: 1.3rem;
    color: color(cool-gray, 50);
  }
}
