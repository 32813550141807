@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.bar {
  width: 100%;
  height: 12px;
  position: relative;
  border-radius: 4px;
  background-color: color(cool-gray, 10);

  &__progress {
    max-width: 0px;
    height: 100%;
    border-radius: 4px;
    animation: loadBar 1s forwards;

    &--show-center {
      &::before,
      &::after {
        width: 1px;
        height: 4px;
        position: absolute;
        top: -4px;
        left: 50%;
        background-color: color(cool-gray, 30);
        content: '';
      }

      &::after {
        top: 12px;
      }
    }

    &--reversed {
      float: right;
    }

    &--color_purple {
      background-image: $bar-home;
    }

    &--color_orange {
      background-image: $bar-away;
    }

    @include cardColors();
  }

  @keyframes loadBar {
    0% {
      max-width: 0%;
    }

    100% {
      max-width: 100%;
    }
  }
}
