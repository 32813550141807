@import '../../../../styles/mixins';

.hockey-logic-page {
  @include tcmsPageContainer();
  padding-top: 24px;
  flex-direction: column;
  gap: 24px;

  &__container {
    display: flex;
    gap: 40px;
  }
}
