@import '../../styles/mixins';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.select-input {
  position: relative;

  &__selected {
    @include defaultInputsStyle();

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 0.8rem;
    $self: &;

    &__field {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      $selfField: &;

      &--placeholder {
        color: color(cool-gray, 60);

        @include getColorClassesFromMap($playerWithOnOffColorMap, $selfField) using ($color) {
          color: $color;
        }
      }

      &--disabled {
        color: color(cool-gray, 30);

        @include getColorClassesFromMap($playerWithOnOffColorMap, $selfField) using ($color) {
          color: $color;
        }
      }
    }

    &--open {
      @include outlineOnFocus(color(blue));
    }

    &--variant_filter {
      @include filterStyle();

      @include getColorClassesFromMap($playerWithOnOffColorMap, $self) using ($color) {
        color: $color;
        border-color: $color;
        background-color: rgba($color, 0.2);

        &#{$self}--open {
          outline: 0;
        }
      }
    }

    &--variant_tcms {
      @include tcmsInputStyle();
      height: 40px;
      transition: border 0.25s;

      &:hover {
        border: 1px solid colorTcms(slate, 400);
      }
    }

    &--variant_login {
      height: 48px;
    }

    &--variant_tcms#{&}--open {
      border: 1px solid colorTcms(blue, 700);
      outline: 3px solid colorTcms(blue, 100);
      outline-offset: 0px;
    }

    &--variant_login#{&}--open {
      border: 1px solid colorTcms(sky, 500);
      outline: 3px solid colorTcms(sky, 200);
      outline-offset: 0px;
    }

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &__cross-icon {
      @include getColorClassesFromMap($playerWithOnOffColorMap) using ($color) {
        rect {
          fill: $color;
        }
      }
    }

    &__down-arrow-icon {
      @include getColorClassesFromMap($playerWithOnOffColorMap) using ($color) {
        path {
          fill: $color;
        }
      }
    }

    &--error {
      @include borderError();
    }

    &--error#{&}--open {
      outline: 0;
    }
  }

  &__options {
    $border: 1px solid color(gray, 30);

    display: none;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border: $border;
    border-top: 0;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    background-color: color(white);
    animation: rotate-x 300ms ease-in-out forwards;
    transform-origin: top center;
    $selfOpt: &;

    &--drop-up {
      bottom: 100%;
      border-bottom: 0;
      border-top: $border;
      transform-origin: bottom center;
    }

    &--variant_filter {
      @include filterStyle();
      @include getColorClassesFromMap($playerWithOnOffColorMap, $selfOpt) using ($color) {
        border-color: $color;
      }
    }

    &--variant_tcms {
      @include tcmsInputStyle();
    }

    &--variant_login {
      border-color: colorTcms(sky, 500);
    }

    &--visible {
      z-index: 100;
      display: block;
      overflow: hidden;
    }

    &--scrollable {
      overflow-y: scroll;
    }
  }
}

@keyframes rotate-x {
  from {
    transform: rotateX(-90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}
