@import '../../../styles/functions';

.video-center-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid color(cool-gray, 20);

  &__title {
    font-size: 2rem;
    margin: 0;
  }

  &__actions-container {
    display: flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;
  }

  &__action {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;

    svg {
      height: 1.6rem;
      width: auto;
    }

    p {
      font-size: 1.4rem;
      margin: 0;
    }

    &:hover,
    &:focus {
      p {
        text-decoration: underline;
      }
    }
  }
}
