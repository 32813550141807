@import '../../../styles/functions';

.shot-flow-graph-tooltip {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 140px;
  padding: 0.3rem 0.5rem;
  top: 1rem;
  left: 1rem;
  border-radius: 3px;
  background-color: color(cool-gray, 80);
  color: color(white);
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  pointer-events: none;
  animation: showTooltip 0.2s ease-in-out forwards;

  span {
    height: 21px;
    font-size: 1.2rem;
  }

  span#{&}__name {
    height: 22px;
    font-size: 1.4rem;
  }

  @keyframes showTooltip {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }
}
