@import '../../../styles/functions';
@import '../../../styles/mixins';

.tcms-item-with-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  $self: &;

  &:hover &__body {
    background-color: colorTcms(slate, 200);
  }

  &--selected {
    &:hover #{$self}__body {
      background-color: colorTcms(slate, 300);
    }

    #{$self}__body {
      background-color: colorTcms(slate, 300);

      &__content__dropdown-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 1.2rem;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    gap: 1.2rem;
    background-color: colorTcms(slate, 100);
    box-sizing: border-box;
    cursor: pointer;

    &__label {
      width: 100%;
      color: colorTcms(slate, 950);
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &__dropdown-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;
        @include disableUserSelect();
      }
    }
  }

  &__dropdown {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
}
