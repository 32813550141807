@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.shot-map-content {
  @include shotsAndPassesContentsStyles();

  &__main {
    @include shotsAndPassesMainClassStyles();
    box-sizing: border-box;

    &__form-box__save-button button {
      margin-top: 0.8rem;
      width: 100%;
    }
  }
}
