@import '../../../styles/mixins.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/colorVariables.scss';

.login-input {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  $self: &;

  &__title {
    padding-bottom: 0.4rem;
    color: colorTcms(slate, 950);
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.8rem;
  }

  &__input {
    position: relative;
    width: 100%;

    &__prefix-icon {
      position: absolute;
      top: calc(50% + 2px);
      left: 12px;
      transform: translateY(-50%);
    }

    input {
      width: 100%;
      padding: 14px 46px;
      border: 1px solid colorTcms(slate, 300);
      border-radius: 4px;
      outline: 0;
      box-sizing: border-box;
      &::placeholder {
        font-family: $default-font-family;
        color: colorTcms(slate, 400);
        font-size: 1.4rem;
      }

      &:focus {
        border: 1px solid colorTcms(sky, 500);
        outline: 3px solid colorTcms(sky, 200);
      }

      &:not(:focus) {
        & ~ #{$self}__input__prefix-icon svg,
        & ~ #{$self}__input__suffix-icon svg {
          line,
          path,
          polyline {
            stroke: colorTcms(slate, 300);
          }
        }
      }
    }

    &__suffix-icon {
      position: absolute;
      top: calc(50% + 2px);
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
      @include disableUserSelect();
    }
  }

  &--is-error {
    #{$self}__input input {
      color: colorTcms(red, 500);
      border: 1px solid colorTcms(red, 500);
      outline: 3px solid colorTcms(red, 200);

      & ~ #{$self}__input__prefix-icon svg,
      & ~ #{$self}__input__suffix-icon svg {
        line,
        path,
        polyline {
          stroke: colorTcms(slate, 950);
        }
      }
    }
  }
}
