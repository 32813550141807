@import '../../../styles/functions';

.hokej-player-management-row {
  width: 100%;
  vertical-align: middle;

  td {
    padding: 0.8rem;
    border-bottom: 1px solid color(cool-gray, 20);

    p {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  &__player {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.4rem;
    padding: 0.4rem;
    border-radius: 4px;
    background-color: color(cool-gray, 10);
    width: fit-content;

    img {
      height: 20px;
      width: auto;
    }
  }

  &__email {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;

    .input {
      height: 26px;
      font-size: 1.2rem;
    }
  }

  &__clips {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;

    &__icon-button {
      cursor: pointer;
    }
  }

  &__rolldown {
    width: 100%;
    vertical-align: middle;
    background-color: color(cool-gray, 10);

    td {
      padding: 0.2rem 0.8rem;
    }

    &--first {
      td {
        padding-top: 0.8rem;
      }
    }

    &--last {
      td {
        padding-bottom: 0.8rem;
      }
    }
  }

  &__language {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
  }

  &__language-switch {
    height: 22px;
    width: 40px;
    background-color: color(cool-gray, 20);
    border: none;
    border-radius: 100vw;
    position: relative;
    cursor: pointer;
    -webkit-transition: r 0.4s;
    transition: 0.4s;

    &--right {
      background-color: color(blue);
    }
  }

  &__language-inner {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: color(white);
    left: 3px;
    top: 3px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &--right {
      right: 3px;
      left: unset;
    }
  }
}
