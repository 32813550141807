.video-center-select-item-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &--pointer {
    cursor: pointer;
  }

  p {
    font-size: 1.4rem;
    margin: 0;
  }

  &__info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.6rem;
  }

  &__team-and-date {
    width: 7.6rem;
    text-align: center;

    &--hide-player {
      text-align: start;
    }
  }

  &__time {
    width: 4rem;
    text-align: center;

    &--time-end {
      width: 10rem;
    }
  }
}
