.video-center-controls {
  padding: 3rem 2.6rem 2rem;
  background-color: #f2f4f8;

  --rs-slider-progress: #1d5cff;
  --rs-slider-thumb-border: #1d5cff;
  --rs-slider-bar: #f2f4f8;
  --rs-slider-hover-bar: #dde1e6;
  --rs-slider-thumb-bg: white;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(#38afff, 0.25);

  &__slider {
    margin-bottom: 6rem;
    position: relative;
  }

  &__center {
    position: absolute;
    width: 4px;
    height: 20px;
    background-color: #1d5cff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100vw;
  }

  &__inputs {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    gap: 1.6rem;
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.6rem;
    margin-left: auto;

    .button {
      margin-bottom: 4px;
    }
  }
}
