@import '../../../styles/functions';

.shot-flow-graph {
  display: flex;
  position: relative;
  width: 496px;
  height: 530px;
  flex-direction: column;
  justify-content: center;
  padding-left: 34px;
  padding-bottom: 34px;

  &__title {
    width: 100%;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
    text-align: center;
  }

  &__container {
    position: relative;
    width: 496px;
    height: 496px;
    background-image: url('../../../assets/bgsa.svg');
    border-radius: 8px;

    &__box {
      display: flex;
      position: absolute;
      height: 19px;
      justify-content: center;
      align-items: center;
      padding: 0 0.8rem;
      border-radius: 0.4rem;
      background-color: color(white);
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;

      &--x {
        &_1 {
          bottom: 8px;
          left: 8px;
        }

        &_2 {
          bottom: 8px;
          left: 178px;
        }

        &_3 {
          bottom: 8px;
          left: 298px;
        }

        &_4 {
          bottom: 8px;
          right: 8px;
        }
      }

      &--y {
        &_1 {
          top: 8px;
          right: 8px;
        }

        &_2 {
          top: 300px;
          right: 8px;
        }

        &_3 {
          top: 188px;
          right: 8px;
        }
      }
    }
  }
}
