@import '../../../../styles/mixins';

.user-page {
  flex-direction: column;
  @include tcmsPageContainer();
  @include tcmsSharedToolbar();

  &__content {
    position: relative;
    flex-direction: column;
    flex-grow: unset;
    padding-top: 6.5rem;

    @include media-query(xl) {
      overflow-x: auto;
    }
  }
}
