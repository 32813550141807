@import '../../../styles/mixins';
@import '../../../styles/functions';

.modal-content {
  display: flex;
  width: 760px;
  min-height: 300px;
  max-height: calc(100vh - 200px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include media-query(m) {
    width: 500px;
  }

  &__news {
    display: flex;
    max-height: calc(100vh - 432px);
    min-height: 100px;
    padding-right: 2rem;
    flex-direction: column;
    margin-bottom: 3.2rem;
    color: color(cool-gray, 100);
    overflow-y: auto;
  }

  h3 {
    margin-bottom: 4rem;
    font-size: 2.4rem;
    font-weight: bold;
  }

  .checkbox-with-description {
    margin-bottom: 1.9rem;
  }

  .button {
    width: 23.2rem;
  }
}
