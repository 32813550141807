@import '../../../styles/mixins';

.change-dataset {
  @include editDatasetComponentStyles();

  &__content {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;

    &__rename-box {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      &--hide {
        visibility: hidden;
      }
    }
  }
}
