@import '../../../styles/functions';

.team-selection-card {
  display: flex;
  width: 80px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid colorTcms(slate, 300);
  background-color: color(white);
  transition: border 0.25s;
  cursor: pointer;

  &:hover {
    border: 1px solid colorTcms(slate, 400);
  }

  &--is-active,
  &--is-active:hover {
    border: 1px solid colorTcms(blue, 700);
    outline: 3px solid colorTcms(blue, 100);
  }

  &__logo {
    max-width: 32px;
    max-height: 32px;
  }
}
