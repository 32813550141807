@import '../../styles/functions';
@import '../../styles/mixins';

.checkbox-with-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  @include disableUserSelect();

  &__button {
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0rem;
    border: 2px solid color(blue);
    border-radius: 4px;
    background-color: color(white);
    cursor: pointer;

    &--checked {
      background: url('../../assets/icons/confirmed.svg') center no-repeat;
      background-color: color(white);
    }
  }
}
