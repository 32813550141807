@import '../../styles/functions';

.dashboard-team-overview {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.6rem;
  }

  &__team {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
  }

  &__stats {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: bold;
      color: color(cool-gray, 100);

      &:first-of-type {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }

      span {
        font-weight: normal;
      }
    }
  }

  &__bars {
    display: flex;
    flex-direction: row;
    gap: 3.2rem;
  }
}
