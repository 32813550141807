@import '../../../styles/functions';

.table-cell {
  box-sizing: border-box;
  height: 40px;
  text-align: center;
  border-bottom: 1px solid color(gray, 20);
  padding: 0 0.8rem;
  $self: &;

  &--variant_tcms {
    padding: 0;
    border-color: colorTcms(slate, 300);

    &#{$self}--bold-border {
      border-width: 2px;
    }
  }

  &--disable-border {
    border-bottom: 0;
  }

  &--bold-border {
    border-bottom: 2px solid color(cool-gray, 60);
  }
}
