@import '../../../../styles/functions';

.goal-net-count-content {
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14%;
    height: 21%;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: color(white);
    border-radius: 50%;
    box-shadow:
      0 2px 12px 0 rgba(color(cool-gray, 100), 0.08),
      0 2px 2px 0 rgba(color(cool-gray, 100), 0.04);

    &--zone_br {
      top: 65%;
      left: 10%;
    }

    &--zone_bl {
      top: 65%;
      right: 10%;
    }

    &--zone_ce {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--zone_fh {
      left: 50%;
      top: 85%;
      transform: translate(-50%, -50%);
    }

    &--zone_ul {
      top: 22%;
      right: 10%;
    }

    &--zone_ur {
      top: 22%;
      left: 10%;
    }
  }
}
