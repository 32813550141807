@import '../../../styles/mixins';
@import '../../../styles/functions';

.card {
  $self: &;
  box-sizing: border-box;
  width: 364px;
  padding: 1.6rem;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(color(cool-gray, 100), 0.12);
  background-color: color(white);
  font-size: 2rem;

  &--has-header &__header div {
    width: auto;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    min-height: 4.4rem;

    &__label {
      display: flex;
      flex-direction: column;

      h3 {
        margin-top: 0;
        margin-bottom: 0;
      }

      &__toi {
        display: flex;
        font-size: 1.2rem;
      }
    }

    &__percentile-box {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;

      &__percentile {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;

        @include cardColors();
        @include cardColorsContrastText();
      }
    }
  }

  &__legend {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding-right: 34px;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    font-size: 1.4rem;
    color: color(cool-gray, 50);

    #{$self}__middle-value {
      margin-left: 1.4rem;
    }
  }
}
