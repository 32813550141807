@import '../../styles/mixins';
@import '../../styles/functions';
@import '../../styles/colorVariables';

.checkbox-button {
  display: flex;
  height: 3.8rem;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  border-radius: 0.8rem;
  overflow: hidden;
  $self: &;

  @include disableUserSelect();

  &--should-check-full-body {
    cursor: pointer;
  }

  &--color_black {
    border: 1px solid color(cool-gray, 30);

    &#{$self}--is-hovered {
      border: 1px solid color(cool-gray, 40);
    }

    &#{$self}--checked {
      border: 1px solid color(cool-gray, 100);

      &#{$self}--is-hovered {
        border: 1px solid color(cool-gray, 70);
      }
    }
  }

  @include getColorClassesFromMap($playerWithOnOffColorStringsMap) using ($color) {
    border: 1px solid color($color, 40);

    &#{$self}--is-hovered {
      border: 1px solid color($color, 50);
    }

    &#{$self}--checked {
      border: 1px solid color($color, 60);

      &#{$self}--is-hovered {
        border: 1px solid color($color, 50);
      }
    }
  }

  &__checkbox {
    display: flex;
    width: 16px;
    height: 16px;
    padding: 1.1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    $selfCheckbox: &;

    svg {
      width: 10px;
      height: 10px;
      padding: 3px;
      border-radius: 4px;
      background-color: color(white);
    }

    &--color_black {
      background-color: color(cool-gray, 30);

      &#{$selfCheckbox}--is-hovered {
        background-color: color(cool-gray, 40);
      }

      &#{$selfCheckbox}--checked {
        background-color: color(cool-gray, 100);

        path {
          fill: color(cool-gray, 100);
        }

        &#{$selfCheckbox}--is-hovered {
          background-color: color(cool-gray, 70);
        }
      }
    }

    @include getColorClassesFromMap($playerWithOnOffColorStringsMap) using ($color) {
      background-color: color($color, 40);

      &#{$selfCheckbox}--is-hovered {
        background-color: color($color, 50);
      }

      &#{$selfCheckbox}--checked {
        background-color: color($color, 60);

        path {
          fill: color($color, 60);
        }

        &#{$selfCheckbox}--is-hovered {
          background-color: color($color, 50);
        }
      }
    }
  }

  &__content {
    width: calc(100% - 38px);
    min-height: 3.8rem;
    height: 100%;
    padding: 0px 1rem;
    background-color: color(white);
    $selfContent: &;

    &--color_black {
      &#{$selfContent}--is-hovered {
        background-color: color(cool-gray, 10);
      }
    }

    @include getColorClassesFromMap($playerWithOnOffColorStringsMap) using ($color) {
      color: color($color, 60);

      &#{$selfContent}--is-hovered {
        background-color: rgba(color($color, 40), 0.1);
      }
    }
  }
}
