@import '../../../styles/functions';

.basic-column-header {
  display: flex;
  align-items: center;
  min-width: 30px;
  $self: &;

  &--is-sortable {
    cursor: pointer;
  }

  &--variant_tcms {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.4rem;
    color: colorTcms(slate, 500);
  }

  &__arrows {
    visibility: hidden;

    &--sorted {
      visibility: visible;
    }

    &__arrow {
      path#caret-up-down-up,
      path#caret-up-down-down {
        fill: colorTcms(slate, 200);
      }

      &--sort-direction_asc path#caret-up-down-up {
        fill: colorTcms(slate, 500);
      }

      &--sort-direction_desc path#caret-up-down-down {
        fill: colorTcms(slate, 500);
      }
    }
  }
}

.basic-column-cell {
  &--is-sorted {
    font-weight: bold;
  }

  &--variant_tcms {
    font-weight: normal;
  }
}
