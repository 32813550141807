@import '../../../styles/functions';

.navigation-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 62px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  cursor: pointer;
  text-decoration: none;
  color: colorTcms(slate, 500);
  transition: 0.3s;

  &:hover {
    color: colorTcms(slate, 600);

    path:first-child {
      fill: colorTcms(slate, 600);
      transition: 0.3s;
    }
  }

  &--is-active,
  &--is-active:hover {
    color: colorTcms(blue, 700);

    path:first-child {
      fill: colorTcms(blue, 700);
    }
  }
}
