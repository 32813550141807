@import '../../../../styles/functions';

.player-card-content-component {
  flex-grow: 1;
  background-color: color(cool-gray, 10);
  display: flex;
  flex-direction: column;

  &--is-loading {
    background-color: color(white);
  }

  &__card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 3.2rem;
    padding: 3.2rem 0;
    max-width: 150rem;
    margin: 0 auto;
    justify-content: center;
  }
}
