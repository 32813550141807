.heatmap-games {
  display: flex;
  justify-content: center;

  &__canvas-container {
    display: flex;
    flex-wrap: nowrap;
    height: 512px;
    border-radius: 135px;
    overflow: hidden;
  }

  &__canvas-map {
    background: url(../../../assets/playground/hriste_pulka_512x512.svg) no-repeat;
    transform: rotateZ(-90deg);

    &--away {
      transform: rotateZ(90deg);
    }
  }
}
