.modal-item {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  &__icon {
    margin-right: 3.2rem;
    margin-top: 0.1rem;
  }

  &__text {
    width: 100%;
    margin-bottom: 3.2rem;
    letter-spacing: 0.0125rem;
    line-height: 1.6;

    h4 {
      margin-block-start: 0;
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 0.8rem;
    }

    span {
      margin-top: 0.8rem;
    }

    p {
      font-size: 1.3rem;
      margin-top: 0;
    }
  }
}
