@import '../../../../styles/mixins';

.users-management-columns {
  @include cellBase();
  height: 52px;
  border-bottom: unset;
  font-size: 1.4rem;

  &__id-cell {
    justify-content: flex-start;
    font-weight: normal;
  }

  &__username-cell {
    min-width: 380px;
    justify-content: flex-start;
    font-weight: bold;

    span {
      margin-left: 0.8rem;
      color: colorTcms(slate, 500);
      font-size: 1.2rem;
      font-weight: normal;
    }
  }

  &__names-cell {
    justify-content: flex-start;
    min-width: 120px;
  }

  &__role-cell {
    min-width: 170px;

    @include media-query(l) {
      min-width: 120px;
    }

    div {
      width: 100%;
    }
  }

  &__videocoach-cell {
    min-width: 245px;

    div {
      width: 100%;
    }
  }

  &__disabled-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  &__change-password-cell {
    min-width: 130px;
    display: flex;
    gap: 1.6rem;
  }
}
