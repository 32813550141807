@import '../../../styles/mixins';

.playground {
  position: absolute;
  height: 582px;
  width: 760px;
  $self: &;

  &--is-small {
    width: 462px;
    height: 353px;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__canvas {
    width: 100%;
    height: 100%;
  }

  &--is-reversed {
    #{$self}__image {
      margin-top: 45px;
      transform: rotate(180deg);
    }
  }

  &--is-responsive {
    @include media-query(xxl) {
      width: 530px;
      height: 406px;

      #{$self}__image {
        width: 100%;
        height: 100%;
      }
    }

    @include media-query(xl) {
      width: 462px;
      height: 353px;
    }
  }
}
