.games-select-list {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__options-box {
    height: 500px;
    overflow-y: auto;

    &__options {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
  }
}
