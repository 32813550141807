@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.compare-players-content {
  flex-grow: 1;
  background-color: color(cool-gray, 10);

  &__content {
    @include inputsAndCardsContainersStyles();
  }

  &__background {
    position: absolute;
    height: 124px;
    width: 100%;
    display: flex;
    background-color: color(white);

    @include media-query(l) {
      height: 170px;
    }
  }
}
