@import '../../../../../../styles/mixins';

.hokej-player-content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__overview-card-list {
    @include card();
    display: flex;
    gap: 15px;
    padding: 16px;

    > div {
      flex: 1;
    }
  }

  &__tables {
    display: flex;
    gap: 40px;
  }
}
