@import './variables';
@import './colorMixins';
@import './functions';

@mixin flexDirection($direction) {
  flex-direction: $direction;
}

@mixin flexDisplay() {
  display: flex;
}

@mixin card() {
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(colorTcms(slate, 500), 0.2);
  background-color: color(white);
}

@mixin tcmsGrayGradient($degree: 180deg, $opacity: 0.5) {
  background-image: linear-gradient(
    $degree,
    rgba(255, 255, 255, $opacity) 0%,
    rgba(253, 254, 254, $opacity) 26%,
    rgba(250, 251, 252, $opacity) 48%,
    rgba(244, 246, 249, $opacity) 67%,
    rgba(238, 241, 246, $opacity) 81%,
    rgba(232, 237, 243, $opacity) 92%,
    rgba(228, 233, 241, $opacity) 98%,
    rgba(226, 232, 240, $opacity) 100%
  );
}

@mixin flexDisplayDirection($direction) {
  @include flexDisplay();
  @include flexDirection($direction);
}

@mixin defaultInputsStyle() {
  font-family: $default-font-family;
  font-size: 1.4rem;
  height: 3.8rem;
  box-sizing: border-box;
  padding: 0 0.8rem;
  border: 1px solid color(gray, 30);
  border-radius: 0.5rem;
  background-color: color(white);
}

@mixin filterStyle() {
  border-radius: 0.8rem;
  border-color: color(gray, 40);
}

@mixin tcmsInputStyle() {
  border-radius: 0.8rem;
  border-color: colorTcms(slate, 300);
}

@mixin outlineOnFocus($color) {
  outline: 1px solid $color;
  outline-offset: -1px;
}

@mixin media-query($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin inputsAndCardsContainersStyles() {
  display: flex;
  justify-content: center;
  padding: 3.25rem 0;
  gap: 3.3rem;

  @include media-query(xxl) {
    gap: 1.7rem;
  }
}

@mixin inputsAndCardsChildrenStyles() {
  display: flex;
  gap: 0.8rem;
  width: 35rem;

  @include media-query(xxl) {
    width: 28.8rem;
  }

  @include media-query(l) {
    flex-direction: column;
    width: 20rem;
  }
}

@mixin percentileBoxStyles() {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

@mixin defaultContentStyles() {
  flex-grow: 1;
  padding: 1rem 0;
  flex-direction: column;
  @include pageContainer();
}

@mixin playgroundBoxesSharedStyles() {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin disableUserSelect() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

@mixin shotsAndPassesContentsStyles {
  flex-direction: column;
  align-items: center;
  @include defaultContentStyles();
  padding: 0;
  padding-top: 2.5rem;
  @include disableUserSelect();
}

@mixin shotsAndPassesMainClassStyles {
  @include defaultContentStyles();
  flex-direction: row;
  justify-content: center;
  gap: 3.2rem;
  padding-bottom: 40px;

  &__playground-wrapper {
    position: relative;
    min-width: 760px;
  }

  &__form-box {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 232px;
    min-width: 232px;

    &__trajectories {
      display: flex;
      flex-direction: column;
    }
  }

  &__form-box__save-button button {
    width: 100%;
  }

  @include media-query(xxl) {
    justify-content: flex-start;

    &__playground-wrapper:has(.playground--is-responsive) {
      width: 530px;
      min-width: 530px;
      height: 406px;
      transform: translateY(54px);
    }
  }

  @include media-query(xl) {
    &__playground-wrapper:has(.playground--is-responsive) {
      width: 462px;
      min-width: 462px;
      height: 353px;
      transform: translateY(54px);
    }
  }
}

@mixin commonAndSubrowsTableStyles() {
  display: flex;
  position: relative;
  width: 100%;
  align-items: flex-start;
  background-color: colorTcms(slate, 100);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &__left-part {
    display: block;
    position: absolute;
    border-top-left-radius: 8px;
    border-spacing: 0;
    box-shadow: 8px 9px 14px -6px rgba(color(black), 0.75);
    font-size: 1.2rem;
    z-index: 2;

    thead tr:first-child {
      th:first-child {
        border-top-left-radius: 8px;
      }
    }
  }

  &__center-part {
    display: flex;
    width: 100%;
    border-top-right-radius: 8px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: color(white);

    &--is-small {
      padding-bottom: 100px;
    }

    &__table {
      width: 100%;
      border-spacing: 0;
      font-size: 1.2rem;
    }
  }

  &__center-part::-webkit-scrollbar {
    display: none;
  }

  &__right-part {
    display: block;
    position: absolute;
    right: 0;
    border-spacing: 0;
    box-shadow: -6px 0 11px -6px rgba(color(black), 0.75);
    border-top-right-radius: 8px;
    font-size: 1.2rem;
    z-index: 2;

    thead tr {
      border-top-right-radius: 8px;

      th {
        border-top-right-radius: 8px;
      }
    }
  }
}

@mixin borderError() {
  border: 2px solid color(red, 50);
  outline: 0;
}

@mixin letterCircleBaseStyles() {
  display: flex;
  width: 17px;
  height: 17px;
  justify-content: center;
  align-items: center;
  border: solid 0.1rem color(blue, 60);
  border-radius: 100%;
  color: color(blue, 60);
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

@mixin cellBase() {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  text-align: center;
  border-bottom: 1px solid color(gray, 20);
  padding: 0 0.8rem;
}

@mixin headCellBase() {
  box-sizing: border-box;
  padding: 0 0.8rem;
}

@mixin editDatasetComponentStyles() {
  &__header {
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    width: 400px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }

  &__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

@mixin dataSettingsHeaderStyles() {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 1.6rem;

    &--gap {
      gap: 0.8rem;
    }
  }

  &__divider {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;
  }
}

@mixin videoSelectListSharedStyles() {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__label {
    margin: 0;
    font-size: 1.8rem;
  }

  &__options-box {
    position: relative;
    height: 545px;
    overflow-y: auto;

    &__options {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
  }
}

@mixin sizes() {
  &--size {
    &_small {
      font-size: 1.2rem;
    }

    &_medium {
      font-size: 1.4rem;
    }

    &_large {
      font-size: 1.6rem;
    }
  }
}

@mixin tcmsButton() {
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  letter-spacing: normal;
  white-space: nowrap;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: normal;
  cursor: pointer;
  transition: 0.3s;
}

@mixin pageContainer() {
  display: flex;
  width: 1540px;
  align-self: center;
  box-sizing: border-box;

  @include media-query(xxl) {
    width: 1300px;
  }

  @include media-query(xl) {
    width: 1100px;
  }

  @include media-query(l) {
    width: 100%;
    padding: 0 2rem;
  }
}

@mixin tcmsPageContainer() {
  display: flex;
  width: 1400px;
  align-self: center;
  flex-grow: 1;
  box-sizing: border-box;

  @include media-query(xxl) {
    width: 1300px;
  }

  @include media-query(xl) {
    width: 1100px;
  }

  @include media-query(l) {
    width: 100%;
    padding: 0 20px;
    align-self: flex-start;
  }
}

@mixin tcmsFilterStyles() {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  > div {
    width: 216px;
  }
}

@mixin tcmsTooltipStyle() {
  box-sizing: border-box;
  min-width: 120px;
  padding: 8px;
  border-radius: 8px;
  background-color: colorTcms(white);
  color: colorTcms(slate, 500);
  font-size: 1.2rem;
  line-height: 1.4rem;
  box-shadow: 0 2px 8px 0 rgba(colorTcms(slate, 500), 0.2);
}

@mixin tcmsTrackingCardContainerStyle() {
  box-sizing: border-box;
  height: 352px;
  width: 680px;
  border-radius: 16px;
  overflow: hidden;
  background-color: colorTcms(white);
  @include card();

  @include media-query(xxl) {
    width: 630px;
  }

  @include media-query(xl) {
    width: 530px;
  }
}

@mixin tcmsOverviewComponentsStyle() {
  @include tcmsTrackingCardContainerStyle();
  @include disableUserSelect();

  &__header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--error {
    color: red;
    text-align: center;
  }
}

@mixin tcmsTable() {
  @include disableUserSelect();
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  &__pagination {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
  }
}

@mixin tcmsSharedToolbar() {
  &__toolbar {
    display: flex;
    margin: 2rem 0;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;

    &__controls {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 1rem;
      gap: 1.6rem;
    }

    @include media-query(xl) {
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &__controls {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: unset;
        margin-top: 1.6rem;
      }
    }
  }
}

@mixin tcmsSharedLogsTableStyles() {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__content {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    @include card();

    &__table {
      display: flex;
      position: relative;
      height: 100%;
      flex-grow: 1;
    }
  }
}

@mixin sidebarDefaultStyles() {
  display: flex;
  position: fixed;
  width: 480px;
  height: calc(100vh - 6.4rem);
  padding: 3.2rem;
  flex-direction: column;
  top: 0;
  right: calc(-480px - 10rem);
  box-shadow: -8px 0 32px 0 rgba(color(cool-gray, 100), 0.5);
  background: color(white);
  overflow-y: auto;
  z-index: 20000;
  transition: 0.3s ease-in-out;

  &--is-opened {
    right: 0;
  }

  &__close {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;
    color: color(blue);
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin trendDefaultStyles() {
  @include defaultContentStyles();
  gap: 34px;

  &__table {
    margin-top: 20px;
    width: 1552px;
    align-self: center;

    @include media-query(xxl) {
      width: 100%;
      align-self: flex-start;
    }

    &__data-selection {
      button {
        width: 100%;
      }
    }

    &__bottom-actions {
      margin-top: 16px;
      margin-bottom: 160px;
      display: flex;
      justify-content: space-between;

      button {
        width: 180px;
      }

      &__add-remove {
        display: flex;
        gap: 1.6rem;
      }
    }
  }

  &__skeleton-wrapper {
    box-sizing: border-box;
    height: 40px;
    padding: 2px 0;
  }
}
