@import '../../../styles/functions';

.pie-chart {
  position: relative;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  $self: &;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: color(purple, 80);
    transform: rotate(-90deg);
  }

  circle {
    fill: none;
    stroke: color(orange, 60);
    stroke-width: 32px;
  }

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
  }

  &--border-bottom {
    border-bottom: 0.1rem solid color(black);
  }

  &--border-right {
    border-right: 0.1rem solid color(black);
  }

  &--gray svg {
    background: color(gray, 40);

    & circle {
      stroke: color(gray, 40);
    }
  }

  &--cross-color-active {
    &:after {
      background-color: color(cool-gray, 20);
    }

    &#{$self}--is-xlast:after {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &#{$self}--is-ylast:after {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &#{$self}--is-last:after {
    border-bottom-right-radius: 8px;
  }
}
