@import '../../../styles/functions';

.download-management-row {
  width: 100%;
  vertical-align: middle;

  td {
    padding: 0.8rem;
    border-bottom: 1px solid color(black);

    p {
      margin: 0;
      font-size: 1.2rem;
      width: fit-content;
    }
  }

  &__type {
    font-weight: bold;
    text-transform: uppercase;
    min-width: 120px;
  }

  &__player {
    font-weight: bold;
    min-width: 200px;

    span {
      font-weight: normal;
      color: color(cool-gray, 40);
    }
  }

  &__time {
    font-weight: normal;
    min-width: 40px;
  }

  &__match {
    border-radius: 4px;
    background-color: color(cool-gray, 10);
    font-size: 1.2rem;
    color: color(cool-gray, 100);
    padding: 0.4rem;
    width: fit-content;
    min-width: 160px;
    text-align: center;
  }
}
