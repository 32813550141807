@import '../../../styles/functions';

.time-interval {
  display: flex;
  height: 40px;
  border: 1px solid colorTcms(slate, 300);
  border-radius: 8px;
  overflow: hidden;
  align-self: flex-start;

  &__letter {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid colorTcms(slate, 300);
    cursor: pointer;

    &:last-child {
      border-right: 0;
    }

    &--active {
      background-color: colorTcms(slate, 300);
    }
  }
}
