.game-timeline-legend {
  box-sizing: border-box;
  display: flex;
  width: 1320px;
  padding-left: 45px;

  &__period-legend {
    flex: 1;
    display: flex;
    justify-content: center;

    &__teams-box {
      display: flex;
      gap: 0.5rem;
    }
  }
}
