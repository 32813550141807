@import '../../../styles/functions';

.pie-chart-tooltip {
  display: none;
  position: relative;
  width: 100px;
  padding: 0.4rem 0.7rem;
  right: calc(-100%);
  bottom: calc(-100%);
  flex-direction: column;
  border: 0.1rem solid color(gray, 40);
  border-radius: 0 0.6rem 0.6rem;
  background: color(gray, 10);
  color: color(black);
  font-size: 1.1rem;
  z-index: 1000;

  &--show {
    display: flex;
  }

  &__blue {
    color: color(purple, 80);
  }

  &__red {
    color: color(orange, 60);
  }
}
