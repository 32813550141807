@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.zones-content {
  flex-direction: column;
  flex-grow: 1;
  @include defaultContentStyles();

  &--is-loading {
    background-color: color(white);
  }

  &__goal-nets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 3rem;
    column-gap: 4rem;
  }

  &__more-button {
    display: flex;
    justify-content: center;

    .button {
      margin-top: 3.2rem;
    }
  }
}
