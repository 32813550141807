.table-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  align-items: flex-end;
  gap: 0.8rem;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__right-part {
    display: flex;
    gap: 2rem;

    &__export-button {
      align-self: flex-end;
      height: 3.8rem;

      button {
        width: 18.8rem;
      }
    }

    &__scroll-buttons {
      display: flex;
      gap: 0.6rem;
    }
  }
}
