.rs-slider {
  position: relative;
}

.rs-slider-with-mark:not(.rs-slider-vertical) {
  margin-bottom: 29px;
}
.rs-slider-bar {
  height: 6px;
  border-radius: 3px;
  background-color: #a2a9b0;
  cursor: pointer;
}

.rs-slider-handle {
  position: absolute;
  top: -50%;
  outline: none;
}
.rs-slider-handle::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--rs-slider-thumb-border);
  background-color: var(--rs-slider-thumb-bg);
  margin-left: -8px;
  margin-top: -2px;
  cursor: pointer;
}

.rs-slider-handle:active::before,
.rs-slider-handle.active::before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.rs-slider-mark {
  position: absolute;
  top: 12px;
  left: -2px;
  white-space: nowrap;
  transform: translateX(-40%);
}
.rs-slider-mark-content {
  font-size: 1.2rem;
}
.rs-slider-mark-last {
  left: auto;
  right: -20px;
}

.rs-slider-graduator {
  width: 100%;
}
.rs-slider-graduator ol,
.rs-slider-graduator li {
  list-style: none;
}
.rs-slider-graduator > ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  width: 100%;
}
.rs-slider-graduator > ol > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
  position: relative;
}
.rs-slider-graduator > ol > li:last-child::after,
.rs-slider-graduator > ol > li::before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--rs-slider-thumb-bg);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid var(--rs-slider-bar);
  margin-left: -4px;
  top: -1px;
}

.rs-slider-graduator > ol > li:last-child::after {
  right: -4px;
}

.rs-slider-graduator > ol > li.rs-slider-pass::before {
  border-color: #3498ff;
  border-color: var(--rs-slider-progress);
}
.rs-slider-graduator > ol > li.rs-slider-active::before {
  visibility: hidden;
}

.rs-slider-progress-bar {
  position: absolute;
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: var(--rs-slider-progress);
}
