.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  min-height: 280px;
  gap: 2rem;

  &__icon {
    width: 6.4rem;
    height: 6.4rem;
  }

  &__message {
    font-size: 1.6rem;
    line-height: 1.38;
  }
}
