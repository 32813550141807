$breakpoints: (
  s: 600px,
  m: 900px,
  l: 1200px,
  xl: 1400px,
  xxl: 1600px,
);

$caption-line-height: 1.6rem;
$caption-margin-bottom: 0.5rem;
$caption-height: $caption-line-height + $caption-margin-bottom;

$input-height: 3.8rem;

$default-font-family:
  'Inter',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;
