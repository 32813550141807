.game-shots-stats {
  width: fit-content;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.4rem;

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 0.4rem;

    p {
      font-size: 1rem;
      font-weight: bold;
      width: 4rem;
      text-align: center;
      margin: 0;
    }
    &--reverse {
      flex-flow: row-reverse nowrap;
    }
  }
}
