@import '../../../styles/functions';

.confirm-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  &__icon {
    width: 64px;
    height: 64px;

    &--type_danger {
      color: color(red, 60);

      path {
        fill: color(red, 60);
      }
    }

    &--type_warning {
      color: color(yellow, 20);

      circle {
        stroke: color(yellow, 20);
      }

      path {
        fill: color(yellow, 20);
      }
    }
  }

  &__content {
    width: 400px;
    text-align: center;
  }

  &__actions {
    display: flex;
    gap: 1.6rem;

    button {
      width: 90px;
    }
  }
}
