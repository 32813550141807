@import '../../../styles/palette';

.functions-item {
  margin-bottom: 3.2rem;
  letter-spacing: 0.01em;
  line-height: 1.6;

  &__title {
    display: flex;
    margin-top: 0;
    margin-bottom: 1.6rem;
    padding-left: 2.4rem;
    background: url('../../../assets/icons/help/info.svg') center left no-repeat;
  }

  &__description {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
  }
}
