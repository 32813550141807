@import '../../styles/functions';

.drag-handle {
  cursor: row-resize;
  padding: 5px;
  width: 20px;

  &__icon {
    height: 4px;
    width: 20px;
    border-top: 2px solid;
    border-bottom: 2px solid;

    &--color_black {
      border-color: color(black);
    }

    &--color_white {
      border-color: color(white);
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.2;
    cursor: default;
  }

  &--has-icon {
    width: unset;
    padding: unset;
  }
}
