@import '../../styles/functions';

.top-players {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;

  &__player {
    display: flex;
    gap: 10px;

    &__value {
      width: 25px;
      height: 27px;
      font-weight: bold;
      line-height: 27px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: color(white);

      &--is-time-attr {
        width: 45px;
      }
    }

    &__name {
      height: 27px;
      padding: 0 8px;
      line-height: 27px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: color(white);
    }
  }

  &__all-players {
    cursor: pointer;
    color: color(blue, 60);
    font-weight: bold;
    padding: 4px 0;
  }
}
