@import '../../../../../../styles/mixins';
@import '../../../../../../styles/functions';

.hokej-player-table-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  > button {
    align-self: flex-end;
  }

  &__table-card {
    @include card();
    display: flex;
    flex-direction: column;
    height: 482px;

    .tcms-table-component {
      &__table .table-cell:first-child {
        width: 32px;
      }

      &__table .table-cell:nth-child(2) {
        width: 48px;
      }
    }
  }

  &__table-caption {
    p {
      margin: 0;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: colorTcms(slate, 500);
    }

    h3 {
      margin: 0;
      margin-bottom: 4px;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: colorTcms(blue, 700);
      font-weight: bold;
    }
  }
}
