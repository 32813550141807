@import '../../styles/functions';

.tab-item {
  border-bottom: 2px solid transparent;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.57;
  cursor: pointer;
  padding-bottom: 1.3rem;
  text-decoration: none;
  color: inherit;

  &--navigation-bar-item {
    padding-bottom: 2.3rem;
  }

  &:hover,
  &:focus,
  &:active,
  &--active {
    color: color(blue);
    font-weight: bold;
  }

  &--active {
    border-bottom: 2px solid color(blue);
    pointer-events: none;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:before {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}
