@import '../../../styles/functions';

.tips-content {
  width: calc(100% - 6.8rem);
  border: 0.2rem solid color(blue);
  border-radius: 1.6rem;
  padding: 3.2rem;

  h4 {
    margin-top: 0;
    margin-bottom: 1.9rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .tips-item {
    border-bottom: 1px solid color(cool-gray, 20);
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    &:nth-child(2) {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: unset;
    }
  }
}
