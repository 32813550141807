@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.tcms-table-component {
  $self: &;
  @include tcmsTable();

  @include media-query(xl) {
    overflow-x: auto;
  }

  &__table {
    display: flex;
    padding: 16px;
    z-index: 1;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      font-size: 1.2rem;

      thead tr th {
        padding: 0.6rem 0.8rem;
      }

      tbody {
        tr:hover {
          td {
            background-color: colorTcms(slate, 100);

            &:first-child {
              border-radius: 0.8rem 0 0 0.8rem;
            }

            &:last-child {
              border-radius: 0 0.8rem 0.8rem 0;
            }
          }
        }
      }
    }
  }

  &--is-for-user-log {
    @include card();
    @include media-query(xl) {
      overflow-x: unset;
    }

    #{$self}__table {
      padding: 0 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: colorTcms(white);
    }

    table {
      thead tr th {
        padding: 1.2rem 0.8rem;
        background-color: colorTcms(white);

        &:last-child {
          border-top-right-radius: 0.8rem;
        }

        &:has(div.user-log-column-header) {
          border-right: 1px solid white;
        }

        &:has(div.user-log-column-header--color_blue) {
          background-color: colorTcms(blue, 700);
        }

        &:has(div.user-log-column-header--color_default) {
          background-color: colorTcms(slate, 200);
        }
      }

      tbody {
        tr {
          background-color: colorTcms(white);

          td {
            &:has(div.basic-column-cell--color_blue) {
              background-color: colorTcms(blue, 100);
              border-right: 1px solid colorTcms(white);
            }

            &:has(div.basic-column-cell--color_slate) {
              background-color: colorTcms(slate, 100);
              border-right: 1px solid colorTcms(white);
            }
          }

          &:hover {
            td {
              background-color: colorTcms(slate, 100);

              &:has(div.basic-column-cell--color_blue) {
                background-color: rgba(colorTcms(blue, 700), 0.3);
              }

              &:has(div.basic-column-cell--color_slate) {
                background-color: colorTcms(slate, 200);
              }

              &:first-child {
                border-radius: 0.8rem 0 0 0.8rem;
              }

              &:last-child {
                border-radius: 0 0.8rem 0.8rem 0;
              }
            }
          }
        }
      }
    }
  }

  &__pagination {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: colorTcms(white);

    &--display-table-bottom-line {
      &:before {
        position: absolute;
        width: calc(100% - 32px);
        height: 1px;
        top: 0px;
        left: 16px;
        background-color: colorTcms(slate, 300);
        content: '';
      }
    }
  }

  &--has-gradient #{$self}__pagination:before {
    position: absolute;
    width: 100%;
    height: 56px;
    top: -56px;
    left: 0;
    @include tcmsGrayGradient();
    content: '';
  }
}
