@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/functions';

.goal-net-stats {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .goal-post {
    fill: color(red, 60);
  }

  &--color_default-color {
    fill: color(cool-gray, 20);
  }

  &__goal {
    position: relative;

    &__content {
      position: absolute;

      &--net-zone_br {
        top: 64.5%;
        left: 9.5%;
      }

      &--net-zone_bl {
        top: 64.5%;
        right: 9.5%;
      }

      &--net-zone_ul {
        top: 16%;
        left: 66%;
      }

      &--net-zone_ur {
        top: 16%;
        right: 66%;
      }

      &--net-zone_fh {
        left: 50%;
        top: 85%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__fields {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 2.4rem;
    gap: 0.8rem;

    &__team-field {
      width: 171px;
    }

    &__goalkeeper-field {
      width: 171px;
    }
  }

  @include cellColors(false);
  @include cardColors(false);
}
