@import '../../styles/functions';

.changelog {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ul {
    margin-bottom: 24px;
    padding-left: 20px;
    margin: 0;

    li {
      margin-bottom: 5px;
    }
  }

  &__log-text {
    max-height: 600px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;

    &__version-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      color: colorTcms(slate, 400);
      font-size: 1.2rem;
      text-align: center;

      span {
        color: colorTcms(dark-blue);
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 3.2rem;
      }
    }
  }
}
