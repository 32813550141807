@import '../../../styles/mixins';

.similarity-column-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.similarity-column-cell {
  width: 5rem;

  @include percentileBoxStyles();
  @include cellColors();
}
