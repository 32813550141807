@import '../../styles/functions';

.skeleton {
  box-sizing: border-box;
  background-color: color(cool-gray, 20);
  background-image: linear-gradient(
    90deg,
    color(cool-gray, 20) 0%,
    color(cool-gray, 30) 50%,
    color(cool-gray, 20) 100%
  );
  background-size: 50% 100%;
  background-position: -150% 0%;
  background-repeat: no-repeat;
  animation: move 2s ease-in-out infinite;

  &--circle {
    border-radius: 50%;
  }

  @keyframes move {
    0% {
      background-position: -150% 0%;
    }
    100% {
      background-position: 350% 0%;
    }
  }
}
