@import '../../../styles/functions';
@import '../../../styles/mixins';

.tcms-dropdown-list {
  display: flex;
  width: 488px;
  flex-direction: column;
  gap: 0.2rem;

  &--is-small {
    width: 296px;
  }
}
