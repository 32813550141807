.time-period-column-header {
  width: 200px;
  text-transform: uppercase;
}

.time-period-column-cell {
  display: flex;
  justify-content: center;

  &__by-games {
    width: 180px;
    display: flex;
    gap: 0.8rem;

    .input {
      width: 50%;
      height: 28px;
    }
  }

  &__by-date {
    display: flex;

    .input {
      height: 28px;
    }
  }
}
