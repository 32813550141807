.eye-dropdown {
  position: relative;
  width: 2.6rem;
  height: 2.6rem;
  text-align: start;
  cursor: pointer;

  &:after {
    display: block;
    content: '';
    width: 2.6rem;
    height: 2.6rem;
    background: url(../../assets/detail_ico.svg) no-repeat;
  }

  &:hover {
    &:after {
      background: url(../../assets/detail_ico_active.svg) no-repeat;
    }
  }

  ul {
    margin: 0;
    padding: 6px 0;
    font-weight: normal;
    list-style-type: none;

    li {
      padding: 0.3rem 0.8rem;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }
}
