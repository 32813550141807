@import '../../../styles/functions';

.selection-sidebar {
  display: flex;
  width: 232px;
  flex-direction: column;
  gap: 1.6rem;
  padding: 3rem 2rem 0 2rem;

  &__caption {
    font-size: 1.3rem;
    font-weight: bold;
    color: color(white);
    margin-bottom: 5px;
  }

  &__close {
    font-size: 1.3rem;
    font-weight: bold;
    color: color(white);
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
