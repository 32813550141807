@import '../../../styles/variables';

.game-report-table-toolbar {
  display: flex;
  margin-bottom: 2.4rem;
  align-items: flex-end;
  gap: 0.8rem;

  > div {
    width: 18.8rem;
  }

  > button {
    width: 18.8rem;
    margin-top: $caption-height;
  }
}
