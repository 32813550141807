@import '../../../styles/palette';

.game-shots {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 3.6rem;

  &__title {
    font-size: 1.6rem;
    min-width: 16rem;
    text-align: center;
  }

  &__team {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 3.8rem;

    .circle-bar {
      margin-bottom: -1.6rem; // height of stats header + gap
    }
  }
}
