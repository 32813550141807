@import '../../styles/functions';

.plus-minus-bar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.8rem;
  background-color: color(gray, 20);
  width: fit-content;
  gap: 0.6rem;

  p {
    margin: 0;
    font-size: 1.2rem;
  }

  &__progress {
    width: 60px;
    height: 16px;
    background-color: color(white);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 4px;
      width: 1px;
      background-color: color(cool-gray, 100);
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      height: 4px;
      width: 1px;
      background-color: color(cool-gray, 100);
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--inner {
      height: 16px;
      background-color: color(teal, 40);
    }
  }
}
