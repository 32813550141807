@import '../../../../styles/mixins';

.users-content-columns {
  @include cellBase();
  height: 52px;
  border-bottom: unset;
  font-size: 1.4rem;

  &__id-cell {
    font-weight: normal;
  }

  &__username-cell {
    display: block;
    height: unset;
    width: 330px;
    font-weight: bold;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    @include media-query(xl) {
      min-width: unset;
      width: 220px;
    }
  }

  &__names-cell {
    min-width: 120px;
  }

  &__role-cell {
    min-width: 100px;
  }

  &__videocoach-cell {
    min-width: 220px;

    @include media-query(xl) {
      min-width: unset;
      width: 205px;
    }
  }

  &__login-list-cell {
    cursor: pointer;
  }
}
