@import '../../../styles/functions';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.videocoach-modal {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;

  &__inputs {
    display: grid;
    grid-template-columns: 0.65fr 0.35fr;
    gap: 2rem;
  }

  &__item {
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 6px;
    }

    textarea {
      @include defaultInputsStyle();
      @include filterStyle();
      height: 12rem;
      width: 100%;
      padding: 0.8rem;

      &:active,
      &:focus {
        @include outlineOnFocus(color(blue));
      }
    }

    &--single {
      grid-column: span 2;
    }
  }

  &__rating-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.6rem;
  }

  &__rating-button {
    cursor: pointer;

    svg {
      width: 38px;
      height: 38px;
    }
  }

  &__players {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.6rem;
    height: 350px;
    overflow-y: auto;

    h3 {
      margin: 0 0 1.2rem 0;
    }
  }

  &__position p {
    margin: 0 0 0.4rem 0;
    font-size: 1.4rem;
    font-weight: bold;
  }

  &__players-container {
    display: flex;
    flex-flow: row wrap;
    gap: 0.8rem;
  }

  &__player {
    padding: 0.6rem;
    border-radius: 4px;
    background-color: color(cool-gray, 10);
    width: fit-content;
    font-size: 1.4rem;
    font-weight: 500;
    color: color(cool-gray, 60);
    cursor: pointer;

    &--active {
      background-color: color(blue);
      color: color(white);
    }
  }

  .button {
    align-self: flex-end;
    margin-top: 1rem;
  }
}
