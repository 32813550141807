@import '../../../styles/mixins';

.select-players-bar {
  @include pageContainer();
  justify-content: center;
  gap: 3.2rem;
  padding-top: 2.5rem;

  > div {
    width: 25.4rem;
  }

  @include media-query(xxl) {
    > div {
      width: 22rem;
    }

    gap: 2rem;
  }

  @include media-query(l) {
    align-self: baseline;
  }
}
