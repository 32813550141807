@import '../../../styles/palette';
@import '../../../styles/functions';
@import '../../../styles/colorVariables';

.game-shots-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.4rem;

  &__title {
    font-size: 1.2rem;
    text-align: right;
    min-width: 13rem;
    margin: 0 1.2rem 0 0;

    span {
      font-weight: bold;
    }
  }

  &__stats {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: color(cool-gray, 10);
    display: grid;
    place-items: center;
    font-size: 1rem;
    font-weight: bold;

    &--count {
      color: color(white);
    }
  }

  &--shotType_high {
    span {
      color: color(green, 60);
    }

    .game-shots-row__stats--count {
      background-image: $bar-green;
    }
  }

  &--shotType_medium {
    span {
      color: color(orange, 60);
    }

    .game-shots-row__stats--count {
      background-image: $bar-orange;
    }
  }

  &--shotType_low {
    span {
      color: color(red, 60);
    }

    .game-shots-row__stats--count {
      background-image: $bar-red;
    }
  }

  &--reverse {
    flex-flow: row-reverse nowrap;

    .game-shots-row__title {
      text-align: left;
      margin: 0 0 0 1.2rem;
    }
  }
}
