@import './functions';
@import './mixins';

.content-info-box {
  padding: 4rem 0;
  text-align: center;
  background-color: color(white);
  color: color(cool-gray, 60);
  font-size: 1.4rem;
}

.data-settings-cross-icon {
  path {
    fill: color(black);
  }

  rect {
    fill: color(white);
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 18px color(white);
  }
}

.extra-header-cell {
  text-transform: none;
  font-weight: bold;
  border-bottom: 2px solid color(cool-gray, 60);
}

.extra-header-cell-similarity {
  text-transform: none;
  font-weight: bold;
}

.with-arrow-top {
  text-transform: none;
  font-weight: bold;
  background: url(../assets/arrow-top.svg) no-repeat;
  background-position: center bottom;
}

.grow-and-flex-column {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  margin: 0;
  background-color: color(cool-gray, 20);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Use with animation mode forward so the final state does not reset
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}

.sortable-helper {
  z-index: 15000;
}
