.video-info-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  &__info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.6rem;
  }

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.6rem;

    p {
      font-size: 1.4rem;
      font-weight: bold;
      margin: 0;
    }
  }

  &__button {
    cursor: pointer;

    svg {
      margin-bottom: -2px;
    }
  }
}
