@import '../../../styles/functions';

.tips-item {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h5 {
      display: flex;
      margin-top: 0;
      margin-bottom: 0;
      color: color(blue);
      font-size: 1.6rem;
      width: 90%;
    }
  }

  &__description {
    display: none;
    margin-top: 0.4rem;
    font-size: 1.4rem;
    letter-spacing: 0.01em;
    line-height: 1.6;

    &--active {
      display: block;
    }
  }
}
