@import '../../../styles/functions';

.trend-tooltip {
  box-sizing: border-box;
  min-width: 120px;
  padding: 8px;
  border-radius: 10px;
  background-color: color(white);
  color: color(cool-gray, 100);
  font-size: 1.2rem;
  box-shadow:
    0 2px 12px 0 rgba(color(cool-gray, 100), 0.25),
    0 1px 2px 0 rgba(color(cool-gray, 100), 0.1);

  &__teams {
    display: flex;
    justify-content: center;
    gap: 0.5rem;

    p {
      margin: 0;
      text-align: center;
      line-height: 1.8rem;
      font-weight: bold;
    }

    &__score {
      color: color(red, 60);

      &--won {
        color: color(green, 40);
      }
    }
  }

  &__score {
    margin: 0;
    text-align: center;
    line-height: 1.6rem;
    font-size: 1rem;
    font-weight: bold;
    color: color(red, 60);
  }

  &__date {
    margin: 0;
    text-align: center;
    line-height: 1.6rem;
    font-size: 1rem;
    color: color(cool-gray, 50);
  }

  &__metric {
    margin: 0;
    text-align: center;
    line-height: 1.8rem;
    margin-top: 0.4rem;
    font-weight: bold;
  }
}
