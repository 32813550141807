@import '../../../../styles/functions';

.video-shifts-checkbox-button {
  &__expanded-list {
    display: flex;
    flex-flow: column;
    background-color: color(cool-gray, 10);
    border-radius: 8px;
  }
}
