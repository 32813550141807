@import '../../styles/functions';
@import '../../styles/variables';
@import '../../styles/mixins';

.bugReport {
  @include sidebarDefaultStyles();
  z-index: 2000000;

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 1.6rem;
  }

  &__title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
  }

  &__message {
    @include defaultInputsStyle();
    @include filterStyle();
    height: 12rem;
    width: 100%;
    padding: 0.8rem;
    margin: 2.4rem 0;

    &:active,
    &:focus {
      @include outlineOnFocus(color(blue));
    }
  }
}
