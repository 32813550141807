@import '../../../../styles/mixins';

.report-content {
  margin: 3.2rem 0;
  flex-direction: column;
  gap: 3.2rem;
  @include pageContainer();

  &__summary-buttons {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    gap: 6rem;

    .button {
      min-width: 23.2rem;
    }

    @include media-query(m) {
      gap: 2rem;
      justify-content: flex-start;

      .button {
        min-width: 20rem;
      }
    }
  }

  &__report-title {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &__main {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &__tables {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9.6rem;

    @include media-query(l) {
      align-items: flex-start;
    }
  }
}
