@import '../../../styles/mixins';
@import '../../../styles/functions';

.deviation-column-header {
  text-align: left;
}

.deviation-column-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &__value {
    box-sizing: border-box;
    width: 6rem;
    color: color(teal, 40);
    font-weight: bold;
  }
}
