@import '../../../styles/functions';

.chart-summary {
  &__header {
    color: colorTcms(blue, 700);
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 4rem;
    padding-bottom: 8px;

    &--is-screen-layout {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding-bottom: 4px;
    }
  }

  &__analytics {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: colorTcms(slate, 500);
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--is-screen-layout {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
